<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Dialog, { Title } from "@smui/dialog";
import Button from "@smui/button";
import { invoicesStore } from "stores/invoicesStore";
import { notifier } from "@beyonk/svelte-notifications";
;
;
export let item;
export let modal;
const modalClose = () => modal === null || modal === void 0 ? void 0 : modal.close();
const { deleteInvoice } = invoicesStore;
const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () {
    if (item) {
        const { success, message } = yield deleteInvoice(item.id);
        if (!success) {
            notifier.danger(message, 2000);
        }
        else {
            modalClose();
        }
    }
});
</script>

<Dialog bind:this={modal} on:MDCDialog:closed={modalClose}>
  <Title id="simple-title">Вы уверены, что хотите удалить счёт?</Title>
  <div class="buttons">
    <Button on:click={handleDelete}>Удалить</Button>&nbsp;
    <Button color="secondary" on:click={modalClose}>Отмена</Button>
  </div>
</Dialog>

<style lang="scss">.buttons {
  margin: 20px;
  display: flex;
  justify-content: flex-end;
}</style>
