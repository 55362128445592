<script lang="ts">import { modalPanelStore } from "stores/modalPanelStore";
import { pop } from "svelte-spa-router";
import { Icon } from "@smui/common";
let { isOpen, component, componentProps } = modalPanelStore;
let overlay;
const closeHandler = () => {
    isOpen.set(false);
    pop();
};
const overlayClickHandler = (e) => {
    if (e.target === overlay) {
        closeHandler();
    }
};
</script>

{#if $component && $componentProps}
  <div
    bind:this={overlay}
    class="modalPanel"
    class:modalPanel__isOpen={$isOpen}
    on:click={overlayClickHandler}
  >
    <div class="modalPanel__panel">
      <div class="modalPanel__header">
        <p class="modalPanel__close" on:click={closeHandler}>
          <Icon class="modalPanel__icon material-icons" style="font-size: 16px"
            >keyboard_backspace</Icon
          >
          Назад
        </p>
      </div>
      <svelte:component this={$component} props={$componentProps} />
    </div>
  </div>
{/if}

<style lang="scss">.modalPanel {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
}
.modalPanel__isOpen {
  display: block;
}
.modalPanel__panel {
  position: fixed;
  right: 0;
  left: 230px;
  background-color: #fefefe;
  height: 100%;
  -webkit-animation-name: slideIn;
          animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  padding: 30px 18px;
  overflow: auto;
}
@-webkit-keyframes slideIn {
  from {
    right: -100%;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
@keyframes slideIn {
  from {
    right: -100%;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.modalPanel__close {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}</style>
