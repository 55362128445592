<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from "svelte";
import { push } from "svelte-spa-router";
import debounce from "lodash.debounce";
import TextInput from "../../shared/Fields/Text.svelte";
import Tab from "@smui/tab";
import TabBar from "@smui/tab-bar";
import { Label } from "@smui/button";
import Checkbox from "@smui/checkbox";
import FormField from "@smui/form-field";
import ProjectTable from "./ProjectTable/index.svelte";
import MilestonesTable from "./MilestoneTable/index.svelte";
import Canban from "../../shared/Canban/index.svelte";
import Spinner from "../../shared/Spinner/index.svelte";
import CanbanCardMilestone from "./CanbanCard/MilestoneCard.svelte";
import CanbanCardProject from "./CanbanCard/ProjectCard.svelte";
import CustomSelect from "../../shared/CustomSelect/index.svelte";
import { projectsStore } from "stores/projectsStore";
import { milestonesStore } from "stores/milestonesStore";
import { authStore } from "stores/authStore";
import { modalPanelStore } from "stores/modalPanelStore";
import { showArchiveStore } from "stores/showArchivedStore";
import { managersStore } from "stores/managersStore";
import { Paths } from "constants/path";
;
export let params = {};
let { isOpen, component, componentProps } = modalPanelStore;
let { hasPermission } = authStore;
const { initProjects, statusColumns: projectColumns, changeProjectSort, getProjects, errorMessage: errorProject, } = projectsStore;
const { initMilestones, statusColumns: milestoneColumns, changeMilestoneSort, getLiteMilestones, errorMessage: errorMilestone } = milestonesStore;
const { getManagers, managers, projectManagers, accountManagers } = managersStore;
let isInitializing = false;
const tabs = [
    { path: "projectsTab", title: "Проекты" },
    { path: "milestonesTab", title: "Вехи" }
];
const { showArchivedFlag } = showArchiveStore;
function checkboxHandler(e) {
    const checked = e.target.checked;
    showArchiveStore.setShowArchived(checked);
    Promise.all([getLiteMilestones(), getProjects()]);
}
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    if (hasPermission([
        "projects_read",
        "projects_add",
        "projects_update",
        "projects_delete",
        "projects_full_access",
        "projects_read_all"
    ])) {
        isInitializing = true;
        yield Promise.all([initProjects(), initMilestones()]);
        isInitializing = false;
    }
    else {
        push(Paths.main);
    }
}));
function manageModalPanel({ id, tab }) {
    if (tab && id) {
        const componentsMap = {
            projectsTab: { component: ProjectTable, componentProps: { id: Number(id) } },
            milestonesTab: { component: MilestonesTable, componentProps: { id: Number(id) } }
        };
        componentProps.set(componentsMap[tab].componentProps);
        component.set(componentsMap[tab].component);
        isOpen.set(true);
    }
    if (!id) {
        isOpen.set(false);
    }
}
//переменные, которые содержат в себе данные фильтра
const data = {
    name: undefined,
    project_manager: undefined,
    account_manager: undefined,
};
const debouncedSearch = debounce(handleSearch, 400);
function handleSearch(e) {
    data.name = e.detail;
}
function getFiltersObject(data) {
    var _a;
    return Object.assign({}, data.name ? { name: data.name } : {}, data.project_manager ? { project_manager_id: (_a = data.project_manager) === null || _a === void 0 ? void 0 : _a.id } : {}, data.account_manager ? { account_manager_id: data.account_manager.id } : {});
}
const handleSelectManager = (userType, value) => {
    data[userType] = value;
};
$: filters = getFiltersObject(data);
$: updateData(filters, activeTab);
const updateData = (filters, activeTab) => {
    if (Object.keys(filters)) {
        if (activeTab.path === "projectsTab")
            getProjects(filters);
        else
            getLiteMilestones(filters);
    }
};
const customOptionsToDrug = {
    group: "projects",
    disabled: !(hasPermission("projects_update") || hasPermission("projects_full_access"))
};
onMount(() => {
    if (!(($projectManagers === null || $projectManagers === void 0 ? void 0 : $projectManagers.length) && ($accountManagers === null || $accountManagers === void 0 ? void 0 : $accountManagers.length)))
        getManagers();
});
//@ts-ignore
$: if (params)
    manageModalPanel(params);
let activeTab;
$: activeTab = tabs.find((t) => t.path === params.tab) || tabs[0];
</script>

<div class="projects__tabBarWrapper">
  <TabBar active={activeTab} let:tab {tabs}>
    <Tab minWidth on:click={() => push("/projects/" + tab.path)} {tab}>
      <Label>{tab.title}</Label>
    </Tab>
  </TabBar>

  <FormField class="checkbox" title="Показывать архивные">
    <Checkbox checked={$showArchivedFlag} on:change={checkboxHandler}/>
    <span slot="label">Архивные</span>
  </FormField>
  <div class="projects__filters">
    <TextInput bind:value={data.name}
               label="Фильтр по имени"
               on:input={debouncedSearch}
    />
    <CustomSelect
      items={$accountManagers ?? []}
      on:clear={() => handleSelectManager("account_manager", null)}
      on:select={(e) => handleSelectManager("account_manager", e.detail)}
      placeholder="Account-менеджер"
      selectedValue={data.account_manager}
    />
    <CustomSelect
      items={$projectManagers ?? []}
      on:clear={() => handleSelectManager("project_manager", null)}
      on:select={(e) => handleSelectManager("project_manager", e.detail)}
      placeholder="Project-менеджер"
      selectedValue={data.project_manager}
    />
  </div>

</div>

{#if isInitializing}
  <div class="_with-air">
    <Spinner/>
  </div>
{:else if activeTab.path === "projectsTab"}
  {#if $projectColumns}
    <Canban
      columns={$projectColumns || []}
      itemType="projects"
      cardThis={CanbanCardProject}
      {customOptionsToDrug}
      changeSort={changeProjectSort}
      entityIdName="projectId"
    />
  {:else}
    {$errorProject ?? "У вас нет проектов"}
  {/if}
{:else if activeTab.path === "milestonesTab"}
  {#if $milestoneColumns}
    <Canban
      columns={$milestoneColumns || []}
      itemType="milestones"
      cardThis={CanbanCardMilestone}
      {customOptionsToDrug}
      changeSort={changeMilestoneSort}
      entityIdName="projectId"
    />
  {:else}
    <p class="kanban-error"> {$errorMilestone ?? "У вас нет проектов"}</p>

  {/if}
{/if}

<style lang="scss">.kanban-error {
  text-align: center;
  padding: 10px 0;
}

.projects__tabBarWrapper {
  display: flex;
}
.projects__tabBarWrapper :global(.mdc-tab) {
  height: 70px;
}
.projects__tabBarWrapper :global(.mdc-tab-bar) {
  width: unset;
}
.projects__tabBarWrapper :global(.checkbox) {
  cursor: pointer;
}
.projects__tabBarWrapper :global(.checkbox) :global(label) {
  cursor: pointer;
}
.projects__filters {
  display: flex;
  flex-grow: 1;
  padding-left: 10px;
  padding-top: 10px;
}
.projects__filters > :global(div) {
  min-width: 200px;
  margin: 0 10px;
}</style>
