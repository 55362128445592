<script lang="typescript">import { fade } from "svelte/transition";
import { actsStore } from "stores/actsStore";
import { authStore } from "stores/authStore";
import ActCanbanCard from "./ActCanbanCard/index.svelte";
import Canban from "../../../shared/Canban/index.svelte";
import Spinner from "../../../shared/Spinner/index.svelte";
const { acts, statusColumns, changeSort, isLoadingActs, isIniting } = actsStore;
let { hasPermission } = authStore;
const customOptionsToDrug = {
    group: "acts",
    disabled: $isLoadingActs || !hasPermission(["acts_update", "acts_full_access"])
};
</script>

<hr />
<section>
  {#if $isLoadingActs}
    <div class="load" transition:fade={{ duration: 800 }}>
      <span>Идет обновление списка актов</span>
      <Spinner />
    </div>
  {/if}
  {#if !$acts.length && !$isLoadingActs && !$isIniting}
    <p>По вашему запросу актов не найдено</p>
  {/if}
  <Canban
    cardThis={ActCanbanCard}
    {changeSort}
    columns={$statusColumns ? $statusColumns : []}
    {customOptionsToDrug}
    entityIdName="actId"
    itemType="acts"
  />
</section>

<style lang="scss">.load {
  display: flex;
  margin-bottom: 5px;
}
.load span:first-child {
  margin-right: 15px;
}</style>
