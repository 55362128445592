<script lang="ts">import { createEventDispatcher } from "svelte";
;
import { draggable } from "../../actions/draggable";
import CanbanCard from '../CanbanCard/index.svelte';
import { formatter } from "helpers/formatter";
;
export let column;
export let itemType;
export let cardThis;
export let customOptionsToDrug;
export let entityIdName;
let items;
//@ts-ignore
let { name, id, color, revenue } = column;
const dispatch = createEventDispatcher();
const onAdd = (e) => {
    const { to, item, newIndex, oldIndex } = e;
    const statusId = to.dataset.statusId;
    const itemId = item.dataset[entityIdName];
    if (statusId && itemId) {
        dispatch('sort', {
            itemType,
            sortParams: { itemId: Number(itemId), statusId: Number(statusId), oldIndex, newIndex, type: 'adding' }
        });
    }
};
const onEnd = (e) => {
    const { to, from, item, newIndex, oldIndex } = e;
    const statusId = to.dataset.statusId;
    const itemId = item.dataset[entityIdName];
    if (to === from && newIndex !== oldIndex && statusId && itemId) {
        dispatch('sort', {
            itemType,
            sortParams: { statusId: Number(statusId), itemId: Number(itemId), newIndex, oldIndex, type: "sorting" }
        });
    }
};
const dragOptions = Object.assign({ ghostClass: 'canbanColumn--ghost', onAdd,
    onEnd, animation: 150 }, customOptionsToDrug);
$: items = column.items;
</script>

<div class="canbanColumn">
  <div class="canbanColumn__header" style={`background-color: ${color}`}>
    <span class="canbanColumn__title">{name}</span>
    {#if revenue}
      <p class="canbanColumn__revenue">
        Выручка: {formatter.money(revenue)}
      </p>
    {/if}
  </div>
  {#if items && Array.isArray(items)}
    <ul class="canbanColumn__list" data-status-id={id} use:draggable={dragOptions}>
      {#each items as item (item.id)}
        <CanbanCard {item} cardThis={cardThis}/>
      {/each}
    </ul>
  {/if}
</div>

<style lang="scss">.canbanColumn {
  margin: 10px;
  min-width: 300px;
}
.canbanColumn:first-child {
  margin-left: 0;
}
.canbanColumn:last-child {
  margin-right: 0;
}
.canbanColumn :global(&--ghost) {
  opacity: 0.5;
}
.canbanColumn__header {
  padding: 7px 0 7px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  line-height: 1.3;
}
.canbanColumn__title {
  padding: 0 50px;
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}
.canbanColumn__revenue {
  font-size: 12px;
}
.canbanColumn__list {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 166px);
  padding: 16px 15px;
  background: #F8F8F8;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
}
.canbanColumn__list::-webkit-scrollbar {
  width: 8px;
}
.canbanColumn__list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
.canbanColumn__list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}</style>
