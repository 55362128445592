<script lang="ts">import Bookmark from "./Bookmark.svelte";
import Bitrix from "../../../../assets/images/icons/Bitrix24.svg";
import GDrive from "../../../../assets/images/icons/GDrive.svg";
import GDoc from "../../../../assets/images/icons/GDoc.svg";
import Coin from "../../../../assets/images/icons/Coin.svg";
import Notion from "../../../../assets/images/icons/Notion.svg";
import Redmine from "../../../../assets/images/icons/Redmine.svg";
import Gitlab from "../../../../assets/images/icons/Gitlab.svg";
import { authStore } from "stores/authStore";
const { hasRoles, userData } = authStore;
let bookmarksConfig = [
    {
        name: "Notion",
        link: "https://www.notion.so/digitalspectrbook/f716c97475d4422a894e2ccc32ffd35a",
        hasPermission: true,
        icon: Notion
    },
    {
        name: "GDrive",
        link: "https://drive.google.com/drive/u/0/folders/0ANpbBAcr3cx-Uk9PVA",
        hasPermission: true,
        icon: GDrive
    },
    {
        name: "Шаблоны заявлений",
        link: "https://drive.google.com/drive/u/0/folders/16N1SRt82lczfZKufqKnYNhQEEuWVUyxG",
        hasPermission: true,
        icon: GDoc
    }
];
$: if ($userData) {
    bookmarksConfig = [
        {
            name: "Notion",
            link: "https://www.notion.so/digitalspectrbook/f716c97475d4422a894e2ccc32ffd35a",
            hasPermission: true,
            icon: Notion
        },
        {
            name: "Redmine",
            link: "https://redmine.digital-spectr.ru/",
            hasPermission: hasRoles([
                "project_manager",
                "developer",
                "contractor",
                "account_manager",
                "super_admin",
                "top_manager"
            ]),
            icon: Redmine
        },
        {
            name: "Gitlab",
            link: "https://git.digital-spectr.ru",
            hasPermission: hasRoles(["project_manager", "developer"]),
            icon: Gitlab
        },
        {
            name: "GDrive",
            link: "https://drive.google.com/drive/u/0/folders/0ANpbBAcr3cx-Uk9PVA",
            hasPermission: true,
            icon: GDrive
        },
        {
            name: "Bitrix",
            link: "https://atprod.bitrix24.ru/",
            hasPermission: hasRoles(["account_manager", "staffer"]),
            icon: Bitrix
        },
        {
            name: "Шаблоны заявлений",
            link: "https://drive.google.com/drive/u/0/folders/16N1SRt82lczfZKufqKnYNhQEEuWVUyxG",
            hasPermission: true,
            icon: GDoc
        },
        {
            name: "Коины",
            link: "https://digital-spectr.bitrix24.site/",
            hasPermission: true,
            icon: Coin
        }
    ];
}
</script>

<nav>
  <span> Перейти:</span>
  {#each bookmarksConfig as { name, link, icon, hasPermission }}
    {#if hasPermission}
      <Bookmark {name} {link} {icon} />
    {/if}
  {/each}
</nav>

<style lang="scss">span {
  margin-right: 5px;
}

nav {
  display: flex;
  flex-wrap: wrap;
}
nav :global(a):not(:last-child) {
  margin-right: 20px;
}</style>
