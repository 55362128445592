<script lang="ts">import { Icon } from "@smui/common";
import { link } from "svelte-spa-router";
import { formatter } from "helpers/formatter";
;
export let item;
</script>

<li class="act-card card" data-act-id={item.id}>
  <div class="act-card__header">
    <span>{item.project.name}, {item.milestone.name}</span>
    {#if item.isApproved}
      <span>
        <Icon class="material-icons" style={`color:#005457;font-size: 16px; margin-right: 2px`}>
          check_circle_outline
        </Icon>
      </span>
    {/if}
  </div>
  <header class="act-card__row _big">
    <a href={`/acts/${item.id}`} use:link>
      <span class="card__name">  {item.name}</span>
    </a>
  </header>
  <time class="act-card__row" datetime={item.date}>
    {item.date}
  </time>
  <div class="act-card__row _with-icons">
    <span>
      <Icon class="material-icons" style={`color:black;font-size: 16px; margin-right: 2px`}>
        access_time
      </Icon>
      <span>{item.hours}</span>
    </span>
    {#if item.price}
      <span>
        <Icon class="material-icons" style={`color:#049949; font-size: 16px; margin-right: 2px`}>
          monetization_on
        </Icon>
        <span>{formatter.money(item.price)}</span>
      </span>
    {/if}
  </div>
</li>

<style lang="scss">.act-card {
  position: relative;
}
.act-card:last-child {
  margin-bottom: 0;
}
.act-card__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.act-card__row._big {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
  cursor: pointer;
}
.act-card__row._with-icons {
  color: #2b3744;
}
.act-card__row._with-icons span {
  display: flex;
  align-items: center;
}
.act-card__row._with-icons span:first-child {
  margin-right: 10px;
}
.act-card__header {
  display: flex;
  margin-bottom: 10px;
}
.act-card__header span:first-child {
  word-break: break-word;
  flex-grow: 1;
}
.act-card__header span:nth-child(2) {
  margin-left: 10px;
}</style>
