<script lang="ts">;
import { createEventDispatcher } from "svelte";
export let style;
export let isFilled;
export let description;
export let invoice;
export let status_id;
export let isCurrent;
export let color;
const dispatch = createEventDispatcher();
const handleClick = () => {
    if (!isCurrent) {
        dispatch("click", {
            invoiceId: invoice.id,
            status_id,
            description
        });
    }
};
</script>

<div on:click={handleClick} {style} class={`cell ${isFilled ? color : ''}`}>
  <div class="description">{description}</div>
</div>

<style lang="scss">.cell {
  position: relative;
  cursor: pointer;
}
.cell:not(:last-child) {
  border-right: 1px solid var(--gray);
}
.cell.gray {
  background: var(--gray);
}
.cell.green {
  background: var(--green);
}
.cell.yellow {
  background: var(--yellow);
}
.cell:hover > .description {
  display: block;
}

.description {
  display: none;
  position: absolute;
  z-index: 999;
  top: 200%;
  max-width: 400px;
  white-space: pre-wrap;
  left: 0;
  background: #fff;
  border-radius: 4px;
  padding: 2px 4px;
}</style>
