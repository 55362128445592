<script lang="typescript">export let title;
</script>

<header>
  <h3 class="mdc-typography--headline5 acts__header">{title}</h3>
</header>

<style lang="scss">header {
  margin-bottom: 20px;
}</style>
