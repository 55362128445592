<script lang="typescript">import Textfield from "@smui/textfield";
import Checkbox from "@smui/checkbox";
import FormField from "@smui/form-field";
import Select from "svelte-select";
export let value;
export let selectedCompanyType;
export let selectedVatType;
export let checked;
export let label;
export let invalidValue;
let invalidData = false;
export let validate;
export let type;
import { companiesStore } from "stores/companiesStore";
import { vatTypes } from "stores/vatTypesStore";
import { getVatTypeSelectOptions } from "../../../../stores/utils/vatTypeUtils";
const { companyBySuggestion, } = companiesStore;
let items;
if (type === 'select') {
    if (label === 'Тип контаргента') {
        items = [
            "Юридическое лицо",
            "Индивидуальный предприниматель",
            "Самозанятый",
            "Физическое лицо"
        ];
    }
    if (label === 'Применяемый НДС') {
        items = getVatTypeSelectOptions($vatTypes);
    }
}
const validateInput = (e) => {
    var _a;
    invalidData = validate(e.target.value, (_a = $companyBySuggestion.req_bank_bik) === null || _a === void 0 ? void 0 : _a.value);
};
</script>
{#if (type === "text" || type === 'number')}
  <Textfield
    class="companies-modal__field"
    on:input={validateInput}
    invalid={invalidValue || invalidData}
    bind:value
    {label}
    {type}
  />
  {#if invalidValue}<div class="error">{invalidValue}</div>{/if}
{/if}
{#if type === "select" && label === "Тип контаргента"}
  <Select
    {items}
    containerClasses="select-type"
    placeholder="Юридическое лицо"
    selectedValue={items.find((_item, i) => i + 1 == selectedCompanyType)}
    on:select={(e) => (selectedCompanyType = e.detail.index + 1)}
  />
  {#if invalidValue}<div class="error">{invalidValue}</div>{/if}
{/if}
{#if type === "select" && label === "Применяемый НДС"}
  <Select
    {items}
    containerClasses="select-type"
    placeholder="Применяемый НДС"
    selectedValue={items.find((item) => item.value === selectedVatType)}
    on:select={(e) => (selectedVatType = e.detail.value)}
  />
  {#if invalidValue}<div class="error">{invalidValue}</div>{/if}
{/if}
{#if type === "checkbox"}
  <FormField>
    <Checkbox bind:checked />
    <span slot="label">Наше юр. лицо?</span>
  </FormField>
{/if}

<style lang="scss">:global(.select-type) {
  margin-top: 15px;
  margin-bottom: 10px;
}
:global(.select-type.focused) {
  border-color: #6200ee !important;
}

.error {
  color: var(--red);
}

:global(.mdc-form-field) {
  margin-left: -10px;
}</style>
