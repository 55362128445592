<script lang="ts">import { createEventDispatcher } from "svelte";
import Button from "@smui/button";
import Spinner from "../Spinner/index.svelte";
export let loading = false;
export let disabled = false;
const dispatch = createEventDispatcher();
</script>

<Button
  on:click={(e) => dispatch("click", e)}
  variant="raised"
  disabled={disabled || loading}
>
  {#if loading}
    <Spinner class="button__spinner" childClass="button__spinner__child" />
  {:else}
    <slot />
  {/if}
</Button>

<style lang="scss">:global(.button__spinner) {
  margin-left: 10px;
}

:global(.button__spinner__child)::before {
  background-color: #fff !important;
}</style>
