<script lang="typescript">import { push } from "svelte-spa-router";
import UsersTable from "./UsersTable.svelte";
import Filters from "./Filters.svelte";
import User from "./User.svelte";
import { authStore } from "stores/authStore";
import { modalPanelStore } from "stores/modalPanelStore";
import { usersStore } from "stores/usersStore";
import { Paths } from "constants/path";
export let params = {};
let { isOpen, component, componentProps } = modalPanelStore;
let { usersDetailed } = usersStore;
const manageModalPanel = ({ value }) => {
    if (value && isFinite(value)) {
        componentProps.set({ value });
        component.set(User);
        isOpen.set(true);
    }
    else {
        isOpen.set(false);
        push(Paths.users);
    }
};
$: if ($usersDetailed.length)
    manageModalPanel(params);
</script>

<section class="section users">
  <header>
    <h1 class="mdc-typography--headline3 acts__header">Сотрудники</h1>
  </header>
  <Filters />
  <UsersTable />
</section>

<style lang="scss">.users header {
  margin-bottom: 20px;
}</style>
