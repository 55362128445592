<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onDestroy, onMount } from "svelte";
import { push } from "svelte-spa-router";
import { Icon } from "@smui/common";
import Dialog, { Content, Title } from "@smui/dialog";
import ActsFilters from "./ActsFilters/index.svelte";
import ActsCanban from "./ActsCanban/index.svelte";
import ActEdit from "../../shared/Act/Edit/index.svelte";
import ActAdd from "../../shared/Act/Add/index.svelte";
import Spinner from "../../shared/Spinner/index.svelte";
import { authStore } from "stores/authStore";
import { actsStore } from "stores/actsStore";
import { modalPanelStore } from "stores/modalPanelStore";
import { Paths } from "constants/path";
;
export let params = {};
let modal;
let isOpenModal = true;
const { hasPermission } = authStore;
const { initActs, acts, error, clear, isIniting, getActs } = actsStore;
let { isOpen, component, componentProps } = modalPanelStore;
function manageModalPanel({ value }) {
    if (value && isFinite(value)) {
        componentProps.set({ value });
        component.set(ActEdit);
        isOpen.set(true);
    }
    else {
        isOpen.set(false);
        push(Paths.acts);
    }
}
function successAddHandler() {
    return __awaiter(this, void 0, void 0, function* () {
        modal === null || modal === void 0 ? void 0 : modal.close();
        yield getActs();
        isOpenModal = false;
    });
}
const handleOpenModalForAddAct = () => {
    isOpenModal = true;
    setTimeout(() => {
        modal === null || modal === void 0 ? void 0 : modal.open();
    });
};
$: if ($acts.length)
    manageModalPanel(params);
let block = false;
$: block = !!(params === null || params === void 0 ? void 0 : params.value);
onMount(() => {
    if (hasPermission(["acts_add", "acts_update", "acts_read_my_projects", "acts_full_access"]))
        initActs();
    else
        push(Paths.main);
});
onDestroy(clear);
$: console.log("block", block);
</script>

<svelte:head>
  {#if block}
    <style>
      body {
        overflow: hidden;
      }</style>
  {/if}
</svelte:head>
<section class="section acts">
  <header>
    <h1 class="mdc-typography--headline3 acts__header">Акты</h1>
    <Icon
      class="material-icons"
      on:click={handleOpenModalForAddAct}
      style={`color:#6200EE; font-size: 40px;cursor:pointer`}
    >
      add_circle
    </Icon>
  </header>
  <ActsFilters />
  {#if $isIniting}
    <div class="_with-air">
      <Spinner />
    </div>
  {:else if $error}
    {$error}
  {:else}
    <ActsCanban />
  {/if}
  {#if isOpenModal}
    <Dialog bind:this={modal} class="acts__add">
      <Title>Добавить акт</Title>
      <Content>
        <ActAdd on:success={successAddHandler} />
      </Content>
    </Dialog>
  {/if}
</section>

<style lang="scss">.acts header {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.acts header h1 {
  margin-right: 15px;
}
.acts :global(.acts__add .mdc-dialog__surface) {
  overflow: auto;
}
.acts :global(.acts__add .mdc-dialog__content, .acts__add .mdc-dialog__surface) {
  overflow: visible;
}</style>
