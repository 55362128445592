<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onDestroy } from "svelte";
import { link } from "svelte-spa-router";
import { formatter } from "helpers/formatter";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import Button, { Label } from "@smui/button";
import { Icon } from "@smui/common";
import Dialog, { Title, Content, Actions } from "@smui/dialog";
import Spinner from "../../../../shared/Spinner/index.svelte";
import ActAdd from "../../../../shared/Act/Add/index.svelte";
import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
import { actsStore } from "stores/actsStore";
import { authStore } from "stores/authStore";
;
export let milestone;
const { clear, getActs, acts, deleteAct, getStatuses } = actsStore;
const { hasPermission } = authStore;
function getMilestoneActs() {
    return __awaiter(this, void 0, void 0, function* () {
        return getActs({ milestoneId: milestone.id });
    });
}
function deleteClickHandler(id) {
    deleteDialog.open();
    actToDeleteId = id;
}
function openActAddDialogHandler() {
    return __awaiter(this, void 0, void 0, function* () {
        addDialog.open();
        yield getStatuses();
    });
}
function successAddHandler() {
    return __awaiter(this, void 0, void 0, function* () {
        addDialog.close();
        yield getActs({ milestoneId: milestone.id });
    });
}
let isUpdatingAct = false;
function deleteHandler() {
    return __awaiter(this, void 0, void 0, function* () {
        isUpdatingAct = true;
        const { message, success } = yield deleteAct(actToDeleteId);
        if (!success) {
            notifier.danger(message);
            isUpdatingAct = false;
            return;
        }
        notifier.success(message);
        yield getActs({ milestoneId: milestone.id });
        isUpdatingAct = false;
    });
}
const hasAddPermission = hasPermission(["acts_add", "acts_full_access"]);
const hasDeletePermission = hasPermission(["acts_delete", "acts_full_access"]);
let deleteDialog, addDialog, actToDeleteId;
let selectedMilestone = {
    milestoneId: {
        milestone: { id: milestone.id, name: milestone.name },
        project: { id: milestone.project.id, name: milestone.project.name }
    }
};
onDestroy(clear);
</script>

<NotificationDisplay />
<div class="milestoneActs">
  <div
    class:milestoneActs__addBtnWrapper--none={!hasAddPermission}
    class="milestoneActs__addBtnWrapper"
  >
    <Button variant="unelevated" on:click={openActAddDialogHandler}>
      <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 16px 8px 0`}>
        add
      </Icon>
      <Label>Добавить акт</Label>
    </Button>
  </div>

  {#await getMilestoneActs()}
    <div class="milestoneActs__spinnerWrapper">
      <Spinner />
    </div>
  {:then}
    {#if isUpdatingAct}
      <div class="milestoneActs__spinnerWrapper">
        <Spinner />
      </div>
    {:else if $acts.length}
      <DataTable table$aria-label="UserCost">
        <Head>
          <Row>
            <Cell>Название</Cell>
            <Cell>Дата</Cell>
            <Cell>Часы</Cell>
            <Cell>Цена</Cell>
            <Cell>Статус</Cell>
            <Cell>Подтверждение</Cell>
            <Cell />
            {#if hasDeletePermission}
              <Cell />
            {/if}
          </Row>
        </Head>
        <Body>
          {#each $acts as { id, name, date, hours, price, status, isApproved } (id)}
            <Row>
              <Cell>
                <a href={`/acts/${id}`} use:link>
                  {name}
                </a>
              </Cell>
              <Cell>{format(parseISO(date), "dd.MM.yyyy")}</Cell>
              <Cell>{hours}</Cell>
              <Cell>
                {price && price !== 0 ? formatter.money(price) : "-"}
              </Cell>
              <Cell>{status.name}</Cell>
              <Cell>{isApproved ? "Да" : "Нет"}</Cell>
              <Cell>
                <a href={`/acts/${id}`} class="milestoneActs__editLink" use:link>Редактировать</a>
              </Cell>
              {#if hasDeletePermission}
                <Cell>
                  <p class="milestoneActs__delete" on:click={() => deleteClickHandler(id)}>
                    Удалить
                  </p>
                </Cell>
              {/if}
            </Row>
          {/each}
        </Body>
      </DataTable>
    {:else}
      <p class="milestoneActs__empty">Акты отсутствуют</p>
    {/if}
  {/await}
</div>

<Dialog bind:this={deleteDialog} aria-labelledby="simple-title" aria-describedby="simple-content">
  <Title id="simple-title">Вы уверены?</Title>
  <Content id="simple-content">Удалить акт?</Content>
  <Actions>
    <Button>
      <Label>No</Label>
    </Button>
    <Button on:click={() => deleteHandler()}>
      <Label>Yes</Label>
    </Button>
  </Actions>
</Dialog>

<Dialog bind:this={addDialog}>
  <Title>Добавить акт</Title>
  <Content>
    <ActAdd on:success={successAddHandler} defaultValues={selectedMilestone} />
  </Content>
</Dialog>

<style lang="scss">.milestoneActs__addBtnWrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.milestoneActs__addBtnWrapper--none {
  display: none;
}
.milestoneActs__spinnerWrapper {
  display: flex;
  justify-content: center;
}
.milestoneActs__editLink {
  color: #6200ee;
}
.milestoneActs__delete {
  color: red;
}
.milestoneActs__delete:hover {
  cursor: pointer;
  text-decoration: underline;
}
.milestoneActs__empty {
  text-align: center;
}</style>
