<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from "svelte";
import { formatter } from "helpers/formatter";
import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
import { Icon } from "@smui/common";
import Dialog, { Content, Title, Actions } from "@smui/dialog";
import Button, { Label } from "@smui/button";
import CoinModal from "../CoinModal/index.svelte";
import Spinner from "../../../shared/Spinner/index.svelte";
import CoinTable from "../../../shared/CoinTable/index.svelte";
import { usersStore } from "stores/usersStore";
import { authStore } from "stores/authStore";
;
;
export let props;
const { getUsersCoins, getUserTransactions, deleteUserTransaction, saveUserTransaction, changeUserTransaction, usersCoins, isSaveTransaction, coinTransactions, isFetchTransactions } = usersStore;
const { hasPermission } = authStore;
let isModalOpen = false, editMode = false, currentUser, saveError = "", currentValues, transactionIdToDelete, transactionIdToEdit, deleteDialog;
$: $usersCoins && findCurrentUser($usersCoins);
function findCurrentUser(usersCoins) {
    if (usersCoins) {
        currentUser = usersCoins.find((user) => user.id === props.userId);
    }
}
function prepareEditDialog(id) {
    var _a, _b;
    transactionIdToEdit = id;
    const transactionToEdit = $coinTransactions && $coinTransactions.find((transaction) => transaction.id === id);
    if (transactionToEdit) {
        currentValues = {
            name: transactionToEdit.name,
            amount: Math.abs(transactionToEdit.value),
            operation: transactionToEdit.value < 0 ? -1 : 1,
            productId: (_b = (_a = transactionToEdit === null || transactionToEdit === void 0 ? void 0 : transactionToEdit.product) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0
        };
        editMode = true;
        isModalOpen = true;
    }
}
function addHandler(transactionToAdd) {
    return __awaiter(this, void 0, void 0, function* () {
        const { message, success } = editMode
            ? yield changeUserTransaction(transactionToAdd, transactionIdToEdit)
            : yield saveUserTransaction(transactionToAdd);
        if (!success) {
            saveError = message;
        }
        else {
            yield Promise.all([getUserTransactions({ userId: props.userId }), getUsersCoins()]);
            saveError = "";
            notifier.success(message);
            isModalOpen = false;
        }
    });
}
function openDeleteDialog(id) {
    transactionIdToDelete = id;
    deleteDialog === null || deleteDialog === void 0 ? void 0 : deleteDialog.open();
}
function deleteHandler() {
    return __awaiter(this, void 0, void 0, function* () {
        const { message, success } = yield deleteUserTransaction(transactionIdToDelete);
        if (success) {
            yield Promise.all([getUserTransactions({ userId: props.userId }), getUsersCoins()]);
            notifier.success(message);
        }
        else {
            notifier.danger(message);
        }
    });
}
onMount(() => {
    if (!$usersCoins)
        getUsersCoins();
    getUserTransactions({ userId: props.userId });
});
</script>

<div class="coinsTransactions">
  <NotificationDisplay />
  {#if currentUser}
    <h2 class="coinsTransactions__title">
      {currentUser.name}. Баланс: {formatter.coins(currentUser.coins)} коинов
    </h2>
  {/if}

  {#if hasPermission(["coins_update", "coins_add", "coins_full_access"])}
    <div class="coinsTransactions__btnWrapper">
      <Button variant="unelevated" on:click={() => (isModalOpen = true)}>
        <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 16px 8px 0`}>
          add
        </Icon>
        <Label>Добавить транзакцию</Label>
      </Button>
    </div>
  {/if}

  {#if $isFetchTransactions}
    <Spinner />
  {:else if $coinTransactions && $coinTransactions.length}
    <CoinTable
      isEditAvailable
      coinTransactions={$coinTransactions}
      on:delete={(e) => openDeleteDialog(e.detail)}
      on:edit={(e) => prepareEditDialog(e.detail)}
    />
  {:else}
    <p>Транзакции не найдены</p>
  {/if}
</div>

<CoinModal
  {isModalOpen}
  {editMode}
  selectedUserId={props.userId}
  externalValues={currentValues}
  isSaveTransaction={$isSaveTransaction}
  usersCoins={$usersCoins ?? []}
  error={saveError}
  on:save={(e) => addHandler(e.detail)}
  on:close={() => {
    isModalOpen = false;
    editMode = false;
  }}
/>

<Dialog bind:this={deleteDialog} aria-labelledby="simple-title" aria-describedby="simple-content">
  <Title id="simple-title">Вы уверены?</Title>
  <Content id="simple-content">Удалить расход?</Content>
  <Actions>
    <Button>
      <Label>No</Label>
    </Button>
    <Button on:click={deleteHandler}>
      <Label>Yes</Label>
    </Button>
  </Actions>
</Dialog>

<style lang="scss">.coinsTransactions__title {
  margin-bottom: 16px;
}
.coinsTransactions__btnWrapper {
  margin-bottom: 16px;
}</style>
