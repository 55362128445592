<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import { Icon } from "@smui/common";
import InvoiceDelete from "../InvoiceDelete/index.svelte";
import InvoiceDownload from "../InvoiceDownload/index.svelte";
import InvoicePaidStatusChange from "../InvoicePaidStatusChange/index.svelte";
import { invoicesStore } from "stores/invoicesStore";
import Spinner from "../../../../shared/Spinner/index.svelte";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { apiPostInvoiceFile } from "api/methods/invoices/apiPostInvoiceFile";
import { notifier } from "@beyonk/svelte-notifications";
import Pagination from "../../../../shared/Pagination/index.svelte";
import { DEFAULT_LENGTH_LIST } from "constants/constants";
import StatusProgress from "../StatusProgress/index.svelte";
;
;
import { formatErrorMessage } from "helpers/helpers";
import { getVatTypeLabel } from "../../../../../stores/utils/vatTypeUtils";
export let onModalOpen;
const columns = [
    "Номер счёта, имя",
    "Веха, проект",
    "Сумма",
    "Статус",
    "Дата выставления, <br /> оплаты",
    ""
];
const { invoices, isLoading, invoice, currentPositions, emptyPosition, setDefaultFields, getInvoice, getInvoices, editId, filters, statuses, editInvoice } = invoicesStore;
let deleteModal;
let downloadModal;
let currentItem;
let changeStatusModal;
let changeStatusItem;
$: currentPage = ($filters.offset || 0) / DEFAULT_LENGTH_LIST + 1;
const openDeleteModal = (item) => {
    currentItem = item;
    deleteModal === null || deleteModal === void 0 ? void 0 : deleteModal.open();
};
const openDownloadModal = (item) => {
    currentItem = item;
    downloadModal === null || downloadModal === void 0 ? void 0 : downloadModal.open();
};
const openChangeStatusModal = (item) => {
    changeStatusItem = item;
    changeStatusModal === null || changeStatusModal === void 0 ? void 0 : changeStatusModal.open();
};
const editApiStatus = ({ status_id, invoice_id, positions, payment_date }, callbackOnSuccess) => __awaiter(void 0, void 0, void 0, function* () {
    const { success, message } = yield editInvoice({ status_id, positions, payment_date }, invoice_id);
    if (success) {
        notifier.success("Статус успешно изменен", 2000);
        callbackOnSuccess && callbackOnSuccess();
    }
    else {
        notifier.danger(formatErrorMessage(message), 2000);
    }
});
const dispatchEditApiStatus = (e) => __awaiter(void 0, void 0, void 0, function* () {
    yield editApiStatus(e.detail, changeStatusModal === null || changeStatusModal === void 0 ? void 0 : changeStatusModal.close);
});
let progressBarStatuses;
$: progressBarStatuses = {
    Черновик: {
        value: 33,
        description: "Черновик",
        id: ((_a = $statuses.find((item) => item.name === "Черновик")) === null || _a === void 0 ? void 0 : _a.id) || 0,
        color: "gray"
    },
    "Отправлен клиенту": {
        value: 66,
        description: "Отправлен клиенту",
        id: ((_b = $statuses.find((item) => item.name === "Отправлен клиенту")) === null || _b === void 0 ? void 0 : _b.id) || 0,
        color: "yellow"
    },
    Оплачен: {
        value: 100,
        description: "Оплачен",
        id: ((_c = $statuses.find((item) => item.name === "Оплачен")) === null || _c === void 0 ? void 0 : _c.id) || 0,
        color: "green"
    }
};
const handleEditStatus = (event) => __awaiter(void 0, void 0, void 0, function* () {
    var _d, _e;
    const { status_id, invoiceId, description } = event.detail;
    const invoiceData = yield getInvoice(invoiceId);
    const formattedPositions = ((_e = (_d = invoiceData.data) === null || _d === void 0 ? void 0 : _d.positions) === null || _e === void 0 ? void 0 : _e.map((item) => {
        return {
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            unit_id: item.unit.id
        };
    })) || [];
    if (description === "Оплачен") {
        if (invoiceData.data) {
            openChangeStatusModal(Object.assign(Object.assign({}, invoiceData.data), { status_id, formattedPositions }));
        }
    }
    else {
        yield editApiStatus({ status_id, positions: formattedPositions, invoice_id: invoiceId });
    }
    $invoice = setDefaultFields($invoice);
    $currentPositions = [JSON.parse(JSON.stringify($emptyPosition))];
});
const openToEdit = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const { success, message } = yield getInvoice(id);
    if (success) {
        editId.set(id);
        onModalOpen();
    }
    else {
        notifier.danger(message, 2000);
    }
});
const handleChangePage = (e) => {
    filters.update((data) => (Object.assign(Object.assign({}, data), { offset: (e.detail.page - 1) * DEFAULT_LENGTH_LIST })));
    getInvoices();
};
</script>

<div class="table">
  <DataTable>
    <Head>
      <Row>
        {#each columns as column}
          <Cell width="200px">{@html column}</Cell>
        {/each}
      </Row>
    </Head>
    <Body>
      {#if $isLoading}
        <div class="loading">
          <Spinner />
        </div>
      {:else if $invoices?.invoices}
        {#each $invoices?.invoices as item (item.id)}
          <Row>
            <Cell>
              <a class="decorated" href="/" on:click|preventDefault={() => openToEdit(item.id)}>{item.number}</a>
              <div>{item.name}</div>
            </Cell>
            <Cell>
              <div><strong>{item.milestone?.name}</strong></div>
              <div>{item.project?.name}</div>
            </Cell>
            <Cell>
              {item.total_sum && item.total_sum.toFixed(2)} ₽
              <div class="vat">
                {getVatTypeLabel(item.vat_type?.percentage_rate)}{#if (item.vat_type)}&nbsp;— {item.vat.toFixed(2)} ₽{/if}
              </div>
            </Cell>
            <Cell style="overflow:visible"
              ><StatusProgress
                bind:invoice={item}
                statuses={progressBarStatuses}
                on:click={handleEditStatus}
              /></Cell
            >
            <Cell>
              <div>
                <strong
                  >{item.date && format(new Date(item.date), "dd.MM.yyyy", { locale: ru })}</strong
                >
              </div>
              <div>
                {item.payment_date &&
                  format(new Date(item.payment_date), "dd.MM.yyyy", { locale: ru })}
              </div>
            </Cell>
            <Cell>
              <a href="/" on:click|preventDefault={() => openToEdit(item.id)}>
                <Icon class="material-icons icon">edit</Icon>
              </a>&nbsp;
              <a href="/" on:click|preventDefault={() => openDownloadModal(item)}>
                <Icon class="material-icons icon">get_app</Icon>
              </a>&nbsp;
              <a class="detele" href="/" on:click|preventDefault={() => openDeleteModal(item)}>
                <Icon class="material-icons icon">delete_outline</Icon>
              </a>
            </Cell>
          </Row>
        {/each}
      {/if}
    </Body>
  </DataTable>

  {#if !$isLoading}
    <Pagination {currentPage} total={$invoices?.meta?.total} on:change={handleChangePage} />
  {/if}

  <InvoiceDelete item={currentItem} bind:modal={deleteModal} />
  <InvoiceDownload item={currentItem} bind:modal={downloadModal} />
  <InvoicePaidStatusChange
    on:submit={dispatchEditApiStatus}
    item={changeStatusItem}
    bind:modal={changeStatusModal}
  />
</div>

<style lang="scss">.table {
  position: relative;
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 100;
}
.loading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
}

.detele {
  color: var(--red);
}

.decorated {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-underline-offset: 2px;
}

.vat {
  color: var(--gray);
}

:global(strong) {
  font-weight: bold;
}</style>
