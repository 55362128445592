<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Dialog, { Title } from "@smui/dialog";
import Button from "@smui/button";
import Textfield from "@smui/textfield";
;
;
import { format } from "date-fns";
import { createEventDispatcher } from "svelte";
// props
export let item;
export let modal;
// modal
const modalClose = () => modal === null || modal === void 0 ? void 0 : modal.close();
// state
let value = format(new Date(), "yyyy-MM-dd");
$: error = new Date(value).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
// events
const dispatch = createEventDispatcher();
const handleInputChange = (e) => {
    value = e.target.value;
};
const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
    dispatch("submit", {
        status_id: item.status_id,
        positions: item.formattedPositions,
        invoice_id: item.id,
        payment_date: value
    });
});
</script>

<Dialog bind:this={modal} on:MDCDialog:closed={modalClose}>
  <Title>Смена статуса на Оплачен для {item?.name}</Title>
  <div class="container">
    <div class="input">
      <Textfield
        invalid={error}
        required
        variant="outlined"
        type="date"
        label="Дата оплаты"
        bind:value
        on:input={(e) => handleInputChange(e)}
      />
      {#if error}
        <div class="red">Счет не может быть оплачен, если дата оплаты еще не наступила</div>
      {/if}
    </div>

    <div class="buttons">
      <Button disabled={error} on:click={handleSubmit}>Сохранить</Button>&nbsp;
      <Button color="secondary" on:click={modalClose}>Отмена</Button>
    </div>
  </div>
</Dialog>

<style scoped lang="scss">.container {
  margin: 20px;
}

.input {
  margin-bottom: 20px;
}

.red {
  color: var(--red);
}

.buttons {
  display: flex;
  justify-content: flex-end;
}</style>
