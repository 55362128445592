<script lang="typescript">import Card from "@smui/card";
import Logo from "../../shared/Logo/index.svelte";
</script>

<div class="layout__auth">
  <Card class="layout__auth__card">
    <div class="header">
      <Logo />
      <span class="title">Авторизация</span>
    </div>
    <slot />
  </Card>
</div>

<style lang="scss">.layout__auth {
  display: flex;
  height: 100vh;
  width: 100vw;
  background: url("../images/auth-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.title {
  font-size: 34px;
  line-height: 36px;
  text-align: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

:global(.layout__auth__card) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  min-width: 470px;
  min-height: 360px;
  padding: 50px 40px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2) !important;
}

:global(.sign__in) {
  align-self: center;
}</style>
