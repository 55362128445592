<script lang="typescript">import Button from '@smui/button';
import IconButton from "@smui/icon-button";
</script>

<div class="buttons">
  <Button variant="raised" on:click>
    <IconButton class="material-icons">add</IconButton>
    Добавить отсутствие
  </Button>
</div>
<style lang="scss">.buttons {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}</style>
