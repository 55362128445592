<script lang="ts">import { createEventDispatcher } from "svelte";
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import { Icon } from "@smui/common";
import { formatter } from "helpers/formatter";
import { authStore } from "stores/authStore";
;
export let coinTransactions;
export let isEditAvailable = false;
const { hasPermission } = authStore;
const dispatch = createEventDispatcher();
function renderCoins(value) {
    //@ts-ignore
    if (![null, undefined].includes(value)) {
        return `<span style="color: ${value > 0 ? "#049949" : "#F7B21B"}">
        ${value > 0 ? "+" : "-"}
        ${formatter.decimal(Math.abs(value))}
      </span>`;
    }
    else
        return "-";
}
</script>

<div class="coinTable"/>
<DataTable table$aria-label="UserTransactions">
  <Head>
    <Row>
      <Cell>Назначение</Cell>
      <Cell />
      <Cell width="100px">Сумма</Cell>
      {#if isEditAvailable}
        <Cell width="50px"/>
      {/if}
    </Row>
  </Head>
  <Body>
  {#each coinTransactions as {id, name, authorName, value, product}}
    <Row>
      <Cell>
        <div class="coinTable__nameCell">
            <span>
              {name}
            </span>
          <span class="coinTable__authorName" title="Оператор">
              {authorName ?? ""}
            </span>
        </div>
      </Cell>
      <Cell>
        {#if product?.name}
          <div class="coinTable__nameCell">
                <span class="coinTable__authorName">
              Товар
            </span>
            <span>
                {product?.name}
            </span>
          </div>
        {/if}
      </Cell>
      <Cell>
        <div class="coinTable__value">
          {@html renderCoins(value)}
        </div>
      </Cell>

      {#if isEditAvailable}
        <Cell>
          {#if hasPermission(["coins_full_access", "coins_update", "coins_add"])}
            <Icon
              class="material-icons"
              title="Редактировать транзакцию"
              style="cursor: pointer"
              on:click={() => dispatch("edit", id)}
            >
              edit
            </Icon>
          {/if}
          {#if hasPermission(["coins_full_access", "coins_delete"])}
            <Icon
              class="material-icons"
              title="Удалить транзакцию"
              style="cursor: pointer;color: red"
              on:click={() => dispatch("delete", id)}
            >
              close
            </Icon>
          {/if}
        </Cell>
      {/if}
    </Row>
  {/each}
  </Body>
</DataTable>

<style lang="scss">.coinTable__nameCell {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.coinTable__nameCell span {
  display: block;
}
.coinTable__authorName {
  margin-top: 5px;
  letter-spacing: 0.05em;
  color: #6e6893;
}
.coinTable__value {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
}</style>
