<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createEventDispatcher } from 'svelte';
import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
import Dialog, { Title, Content } from '@smui/dialog';
import Button from '@smui/button';
import IconButton from "@smui/icon-button";
import { notifier, NotificationDisplay } from '@beyonk/svelte-notifications';
import Spinner from "../Spinner/index.svelte";
import { daysOffStore } from "stores/daysOffStore";
;
const { daysOff, errorMessage, isLoadingDaysOff, isUpdatingDayOff, dayOff } = daysOffStore;
export let isManageDaysOff = false;
let deleteDayOffModal;
let activeDayOff;
const dispatch = createEventDispatcher();
const handleClick = (mode, data) => {
    dispatch(mode, data);
};
const handleClickDelete = (dayOff) => {
    deleteDayOffModal.open();
    activeDayOff = dayOff;
};
const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () {
    dayOff.delete(activeDayOff.id)
        .then((data) => {
        deleteDayOffModal.close();
        notifier.info(data);
    });
});
</script>

<NotificationDisplay />
<section>
  {#if $isLoadingDaysOff}
    <div class="_with-air">
      <Spinner/>
    </div>
  {:else if !$isLoadingDaysOff && $daysOff.length > 0}
    <DataTable style="width: 100%">
      <Head>
        <Row>
          <Cell>Причина</Cell>
          <Cell>Дата начала</Cell>
          <Cell>Дата окончания</Cell>
          {#if isManageDaysOff}
            <Cell>Сотрудник</Cell>
            <Cell/>
          {/if}
        </Row>
      </Head>
      <Body>
      {#each $daysOff as dayOff (dayOff.id)}
        <Row>
          <Cell>{dayOff.description ?? ""}</Cell>
          <Cell>{dayOff.startDate}</Cell>
          <Cell>{dayOff.endDate}</Cell>
          {#if isManageDaysOff}
            <Cell>{dayOff.userName}</Cell>
            <!--numeric здесь использутся для text-align: right-->
            <Cell numeric>
              <IconButton class="material-icons mode_edit" on:click={() => handleClick("edit",dayOff)}>
                mode_edit
              </IconButton>
              <IconButton class="material-icons" on:click={() => handleClickDelete(dayOff)}>
                delete
              </IconButton>
            </Cell>
          {/if}
        </Row>
      {/each}
      </Body>
    </DataTable>
  {:else}
    <p>{$errorMessage ?? "Нет данных об отсутствиях"}</p>
  {/if}
  <Dialog
    bind:this={deleteDayOffModal}
  >
    <Title>Удалить отсутствие</Title>
    {#if $isUpdatingDayOff}
      <Content>
        <Spinner/>
      </Content>
    {:else }
      <Content>
        Вы уверены, что хотите удалить информацию об отсутствии?
      </Content>
      <footer class="buttons">
        <Button on:click={ () => deleteDayOffModal.close()}>
          Нет, не удалять
        </Button>
        <Button on:click={handleDelete}>
          Да, удалить
        </Button>
      </footer>
    {/if}
  </Dialog>

</section>

<style lang="scss">.buttons {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 15px;
}

:global(._with-air) {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}</style>
