<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Textfield from "@smui/textfield";
import Button from "@smui/button";
import CustomSelect from "../../../../shared/CustomSelect/index.svelte";
import { companiesStore } from "stores/companiesStore";
import { onMount } from "svelte";
import { invoicesStore } from "stores/invoicesStore";
import { toSelectItems } from "helpers/helpers";
import { milestonesStore } from "stores/milestonesStore";
import { vatTypesStore } from "stores/vatTypesStore";
import { ERORRS } from "constants/constants";
;
import { format } from "date-fns";
;
import { getVatTypeLabel, getVatTypeSelectOptions } from "../../../../../stores/utils/vatTypeUtils";
export let onModalClose;
const { companies, getCompanies } = companiesStore;
const { statuses, positionUnits, getPositionUnits, createInvoice, editInvoice, invoice, editId, currentPositions, emptyPosition } = invoicesStore;
const { milestones, getLiteMilestones } = milestonesStore;
const { vatTypes, getVatTypes } = vatTypesStore;
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    yield Promise.all([getCompanies(), getPositionUnits(), getLiteMilestones(), getVatTypes()]);
    $invoice.forEach((item) => {
        var _a, _b, _c;
        if (item.slug === "milestone")
            item.items = toSelectItems($milestones, "name", "id", true);
        if (item.slug === "vat_type") {
            const items = toSelectItems(getVatTypeSelectOptions($vatTypes), "label", "value", true);
            item.items = items;
            const companyItem = $invoice.find((item) => item.slug === 'self_company') || null;
            const relatedVatTypeValue = getRelatedVatTypeValue(((_a = companyItem === null || companyItem === void 0 ? void 0 : companyItem.value) === null || _a === void 0 ? void 0 : _a.id) || null) || items[0] || null;
            item.value = relatedVatTypeValue;
            item.defaultValue = relatedVatTypeValue;
        }
        if (item.slug === "company") {
            const filtered = (_b = $companies.companies) === null || _b === void 0 ? void 0 : _b.filter((item) => !item.is_our_company);
            item.items = toSelectItems(filtered || [], "name", "id", true);
        }
        if (item.slug === "self_company") {
            const filtered = (_c = $companies.companies) === null || _c === void 0 ? void 0 : _c.filter((item) => item.is_our_company);
            const items = toSelectItems(filtered || [], "name", "id", true);
            item.items = items;
            item.value = items[0] || null;
            item.defaultValue = items[0] || null;
        }
        if (item.slug === "status") {
            const items = toSelectItems($statuses, "name", "id", true);
            item.items = items;
            item.value = items.find((item) => item.name === "Черновик") || items[0] || null;
            item.defaultValue = items.find((item) => item.name === "Черновик") || items[0] || null;
        }
        if (item.slug === "date") {
            item.value = format(new Date(), "yyyy-MM-dd");
            item.defaultValue = format(new Date(), "yyyy-MM-dd");
        }
        if (item.slug === "payment_date") {
            const day = new Date();
            day.setDate(day.getDate() + 7);
            item.value = format(day, "yyyy-MM-dd");
            item.defaultValue = format(day, "yyyy-MM-dd");
        }
    });
    $currentPositions = [JSON.parse(JSON.stringify($emptyPosition))];
    $invoice = $invoice;
}));
let unitItems;
//@ts-ignore
$: unitItems = toSelectItems($positionUnits, "name", "id", true);
const handleInputChange = (e, index, positionKey) => {
    const value = e.target.value.trim();
    if (positionKey) {
        if (value) {
            $currentPositions[index].errors[positionKey] = "";
        }
        else {
            $currentPositions[index].errors[positionKey] = ERORRS.REQUIRED;
        }
        $currentPositions[index][positionKey] = value;
        return;
    }
    if (!value) {
        $invoice[index].error = ERORRS.REQUIRED;
    }
    else {
        $invoice[index].error = "";
    }
    $invoice[index].value = value;
};
const getRelatedVatTypeValue = (companyId) => {
    var _a;
    if (!companyId) {
        return null;
    }
    const companyItem = $companies.companies.find((item) => item.id === companyId) || null;
    if (!companyItem) {
        return null;
    }
    const vatTypeId = ((_a = companyItem === null || companyItem === void 0 ? void 0 : companyItem.vat_type) === null || _a === void 0 ? void 0 : _a.id) || null;
    if (!vatTypeId) {
        return null;
    }
    const vatTypeValues = toSelectItems(getVatTypeSelectOptions($vatTypes), "label", "value", true);
    return vatTypeValues.find((item) => item.id === vatTypeId);
};
const handleSelectChange = (e, index, positionKey) => {
    var _a;
    if ($invoice[index]['slug'] === 'self_company') {
        const companyItem = $companies.companies.find((item) => item.id === e.detail.id) || null;
        const vatTypeId = ((_a = companyItem === null || companyItem === void 0 ? void 0 : companyItem.vat_type) === null || _a === void 0 ? void 0 : _a.id) || null;
        if (vatTypeId) {
            const vatTypeValues = toSelectItems(getVatTypeSelectOptions($vatTypes), "label", "value", true);
            const vatTypeValue = vatTypeValues.find((item) => item.id === vatTypeId);
            const vatTypeItem = $invoice.find((item) => item.slug === 'vat_type');
            vatTypeItem.value = vatTypeValue;
        }
    }
    if (positionKey) {
        $currentPositions[index][positionKey] = "";
        $currentPositions[index][positionKey] = e.detail;
        return;
    }
    $invoice[index].error = "";
    $invoice[index].value = e.detail;
};
const handleClear = (index, positionKey) => {
    if (positionKey) {
        $currentPositions[index][positionKey] = ERORRS.REQUIRED;
        $currentPositions[index][positionKey] = null;
        return;
    }
    $invoice[index].value = null;
    $invoice[index].error = ERORRS.REQUIRED;
};
const addPosition = () => {
    $currentPositions = [...$currentPositions, JSON.parse(JSON.stringify($emptyPosition))];
};
const deletePosition = (i) => {
    $currentPositions.splice(i, 1);
    $currentPositions = $currentPositions;
};
const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
    let isValid = true;
    $invoice.forEach((item) => {
        if (!item.value && !item.disabled && item.required) {
            isValid = false;
            item.error = ERORRS.REQUIRED;
        }
        else {
            item.error = "";
        }
    });
    $currentPositions.forEach((item) => {
        const errors = item.errors;
        Object.keys(errors).forEach((key) => {
            if (key === "quantity" || key === "price") {
                if (typeof item[key] === "object") {
                    isValid = false;
                    errors[key] = ERORRS.REQUIRED;
                }
                else {
                    errors[key] = "";
                }
            }
            if (key === 'unit' || key === 'name') {
                if (!item[key]) {
                    isValid = false;
                    errors[key] = ERORRS.REQUIRED;
                }
                else {
                    errors[key] = "";
                }
            }
        });
    });
    $invoice = $invoice;
    $currentPositions = $currentPositions;
    if (!isValid)
        return;
    //@ts-ignore
    const data = $invoice.reduce((acc, field) => {
        var _a;
        if (!field.group &&
            !field.isGroup &&
            field.name !== "planfact_payment_id" &&
            field.name !== "total_sum") {
            acc[field.name] = field.type === "select" ? ((_a = field.value) === null || _a === void 0 ? void 0 : _a.id) || null : field.value;
        }
        return acc;
    }, {});
    //@ts-ignore
    data.positions = $currentPositions.map((item) => {
        var _a;
        return {
            name: item.name,
            unit_id: (_a = item.unit) === null || _a === void 0 ? void 0 : _a.id,
            price: item.price,
            quantity: item.quantity
        };
    });
    //@ts-ignore
    const result = $editId ? yield editInvoice(data, $editId) : yield createInvoice(data);
    if (result.success) {
        onModalClose();
    }
    else {
        Object.entries(result.message).forEach(([key, value]) => {
            const item = $invoice.find((item) => item.name === key);
            if (item) {
                item.error = value[0];
            }
        });
        $invoice = $invoice;
    }
});
</script>

<form on:submit|preventDefault={() => {}} class="form">
  {#each $invoice as { label, type, value, items, disabled, error, hidden, slug, extra }, i}
    {#if !hidden || (hidden && $editId)}
      <div class="item">
        {#if slug === "total_sum"}
          <Textfield
            invalid={error}
            required
            {disabled}
            variant="outlined"
            {type}
            {label}
            bind:value
            on:input={(e) => handleInputChange(e, i)}
          />
          <div class="vat">
            {getVatTypeLabel(extra?.vatPercentageRate || null)}{#if (extra.vat)}&nbsp;— {extra.vat.toFixed(2)} ₽{/if}
          </div>
          {#if error}<div class="red">{error}</div>{/if}
        {:else}
          {#if type !== "select"}
            <Textfield
              invalid={error}
              required
              {disabled}
              variant="outlined"
              {type}
              {label}
              bind:value
              on:input={(e) => handleInputChange(e, i)}
            />
            {#if error}<div class="red">{error}</div>{/if}
          {:else}
            <CustomSelect
              hasError={!!error}
              isWaiting={!items}
              {items}
              isDisabled={disabled}
              placeholder={label}
              selectedValue={value}
              on:select={(e) => handleSelectChange(e, i)}
              on:clear={() => handleClear(i)}
            />
            {#if error}<div class="red">{error}</div>{/if}
          {/if}
        {/if}
      </div>
    {/if}
  {/each}
  <div class="item">
    <h3>Позиции</h3>
  </div>
  {#each $currentPositions as { name, quantity, price, unit, errors }, i}
    <fieldset class="fieldset">
      <legend>{`Позиция ${i + 1}`}</legend>
      <div class="fieldset__item">
        <Textfield
          invalid={errors.name}
          variant="outlined"
          type="text"
          label="Название"
          bind:value={name}
          on:input={(e) => handleInputChange(e, i, "name")}
        />
      </div>
      <div class="fieldset__item">
        <Textfield
          invalid={errors.quantity}
          variant="outlined"
          type="number"
          input$step='any'
          label="Количество"
          input$novalidate=""
          bind:value={quantity}
          on:input={(e) => handleInputChange(e, i, "quantity")}
        />
      </div>
      <div class="fieldset__item">
        <CustomSelect
          hasError={!!errors.unit}
          items={unitItems}
          isWaiting={!unitItems}
          placeholder="Единица измерения"
          selectedValue={unit}
          on:select={(e) => handleSelectChange(e, i, "unit")}
          on:clear={() => handleClear(i, "unit")}
        />
      </div>
      <div class="fieldset__item">
        <Textfield
          invalid={errors.price}
          variant="outlined"
          type="number"
          input$step='any'
          label="Цена"
          input$novalidate=""
          bind:value={price}
          on:input={(e) => handleInputChange(e, i, "price")}
        />
      </div>
      <div class="delete">
        <Button disabled={$currentPositions.length === 1} on:click={() => deletePosition(i)}>Удалить</Button>
      </div>
    </fieldset>
  {/each}
  <div class="item">
    <div class="right">
      <Button on:click={addPosition}>Добавить позицию</Button>
    </div>
  </div>
  <div class="buttons">
    <Button on:click={onSubmit}>Сохранить</Button>
  </div>
</form>

<style lang="scss">.fieldset {
  margin: 10px;
  padding: 10px 30px;
}
.fieldset__item {
  padding: 10px 0;
}

.item {
  padding: 10px 40px;
}

.delete {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.buttons {
  margin: 15px 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 35px;
}

.red {
  color: var(--red);
}

.vat {
  color: var(--gray);
}</style>
