<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onDestroy } from "svelte";
import { Icon } from "@smui/common";
import Button, { Label } from "@smui/button";
import Dialog, { Title } from "@smui/dialog";
import Select, { Option } from "@smui/select";
import Textfield from "@smui/textfield";
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import Tab from "@smui/tab";
import TabBar from "@smui/tab-bar";
import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
import { link } from "svelte-spa-router";
import Managers from "../../../shared/Managers/index.svelte";
import LogsTable from "../../../shared/LogsTable/index.svelte";
import Spinner from "../../../shared/Spinner/index.svelte";
import MilestoneActs from "./MilestoneActs/index.svelte";
import OtherCosts from "../../../shared/OtherCosts/index.svelte";
import Invoices from './InvoiceTabContent/index.svelte';
;
import { milestonesStore } from "stores/milestonesStore";
import { authStore } from "stores/authStore";
import { modalPanelStore } from "stores/modalPanelStore";
import { projectsStore } from "stores/projectsStore";
import { formatter } from "../../../../helpers/formatter";
const { hasPermission } = authStore;
export let props;
const { statusColumns, statuses, saveMilestone, getDetailMilestone, detailMilestone } = milestonesStore;
let changeStatusModal, changeBillingTypeModal, changeGuaranteeModal, changeFinishDateModal, changeTrackingPeriod;
let availableBillingTypes = ["Time&Material", "Fixed Price"];
let availableGuaranteeValues = [
    {
        label: "Гарантийный",
        isGuarantee: true
    },
    {
        label: "Негарантийный",
        isGuarantee: false
    }
];
let selectedStatusId, selectedBillingType, selectedGuaranteeValue, newMaxBillingHours;
let finishDateValue = "";
let trackingStartValue = "";
let trackingEndValue = "";
let inputDialogs;
let isEditable = hasPermission(["projects_update", "projects_full_access"]);
// получаем детальную информацию о вехе при смене пропсов,
// обновляем переменные выбранных селектов
$: if (props)
    init(props);
let isInitializing = false;
function init(props) {
    return __awaiter(this, void 0, void 0, function* () {
        isInitializing = true;
        yield getDetailMilestone(props.id);
        modalPanelStore.isOpen.set(true);
        updateSelectedValues($detailMilestone);
        isInitializing = false;
    });
}
let buttonDisabler = false;
const INFO_TAB = "Инфо";
const CHANGES_TAB = "История изменений";
const ACTS_TAB = "Акты";
const ACCOUNTING = "Счета";
let tabs = [
    INFO_TAB,
    CHANGES_TAB,
    ACCOUNTING,
    hasPermission([
        "acts_add",
        "acts_update",
        "acts_read_my_projects",
        "acts_full_access",
        "acts_read_poffice"
    ])
        ? ACTS_TAB
        : null
];
let active;
function updateSelectedValues(milestone) {
    var _a;
    selectedStatusId = ($statuses === null || $statuses === void 0 ? void 0 : $statuses.find((st) => st.id === milestone.status.id).id.toString()) || null;
    selectedBillingType = milestone.billingType;
    finishDateValue = milestone.finish_date || "";
    trackingStartValue = milestone.tracking_start || "";
    trackingEndValue = milestone.tracking_end || "";
    selectedGuaranteeValue = (_a = availableGuaranteeValues.find((v) => v.isGuarantee === milestone.isGuarantee)) === null || _a === void 0 ? void 0 : _a.label;
    newMaxBillingHours = milestone.maxBillingHours;
    inputDialogs = [
        {
            id: "maxBillingHours",
            modalTitle: "Введите максимальное кол-во billable часов",
            value: milestone.maxBillingHours || 0,
            modalHandler: null
        },
        {
            id: "planRevenue",
            modalTitle: "Введите планируемую выручку",
            value: milestone.planRevenue || 0,
            modalHandler: null
        },
        {
            id: "factRevenue",
            modalTitle: "Введите фактическую выручку",
            value: milestone.factRevenue || 0,
            modalHandler: null
        }
    ];
}
function toggleIsArchived() {
    return __awaiter(this, void 0, void 0, function* () {
        const { message, success } = yield saveMilestone({
            isArchived: !$detailMilestone.isArchived
        }, $detailMilestone.id);
        if (success) {
            notifier.success(message, 2000);
            yield getDetailMilestone($detailMilestone.id);
            yield Promise.all([milestonesStore.getLiteMilestones(), projectsStore.getProjects()]);
        }
        else {
            selectedStatusId = $statuses.find((st) => st.id === $detailMilestone.status.id).id.toString();
            notifier.danger(message, 3000);
        }
    });
}
function saveHandler(type, value) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        buttonDisabler = true;
        let modifiedParam;
        if (type === 'finish_date') {
            modifiedParam = {
                finish_date: finishDateValue
            };
        }
        if (type === 'tracking_period') {
            modifiedParam = {
                tracking_start: trackingStartValue,
                tracking_end: trackingEndValue,
            };
        }
        if (type === "status") {
            const minColumnSort = ((_b = (_a = $statusColumns.find((col) => col.id === Number(selectedStatusId)).items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.sort) || null;
            modifiedParam = {
                statusId: $statuses.find((st) => st.id === Number(selectedStatusId)).id,
                sort: minColumnSort ? minColumnSort - 1000 : 5000
            };
        }
        if (type === "billingType") {
            modifiedParam = {
                billingType: selectedBillingType
            };
        }
        if (type === "isGuarantee") {
            modifiedParam = {
                isGuarantee: availableGuaranteeValues.find((v) => v.label === selectedGuaranteeValue)
                    .isGuarantee
            };
        }
        if ((value !== undefined && type === "maxBillingHours") ||
            type === "factRevenue" ||
            type === "planRevenue") {
            const param = {};
            param[type] = Number(value);
            modifiedParam = param;
        }
        const { message, success } = yield saveMilestone(modifiedParam, $detailMilestone.id);
        if (success) {
            yield getDetailMilestone($detailMilestone.id);
            notifier.success(message, 2000);
        }
        else {
            selectedStatusId = $statuses.find((st) => st.id === $detailMilestone.status.id).id.toString();
            notifier.danger(message, 3000);
        }
        if (value !== undefined) {
            inputDialogs.forEach((dial) => dial.modalHandler.close());
        }
        else {
            [changeStatusModal, changeBillingTypeModal, changeGuaranteeModal].forEach((modal) => modal.close());
        }
        buttonDisabler = false;
    });
}
function getLastWeekColor() {
    let color = "inherit";
    if ($detailMilestone.billingType === "Fixed Price" &&
        $detailMilestone.spentHours.totalHours >= $detailMilestone.maxBillingHours) {
        if ($detailMilestone.project.spentHours.totalHours -
            $detailMilestone.spentHours.lastWeekHours <=
            $detailMilestone.maxBillingHours)
            color = "yellow";
        else
            color = "red";
    }
    return color;
}
function successCostHandler(e) {
    return __awaiter(this, void 0, void 0, function* () {
        yield init(props);
        notifier.success(e.detail);
    });
}
function deleteCostHandler(response) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!response.success)
            notifier.danger(response.message);
        else {
            yield init(props);
            notifier.success("Расход удален");
        }
    });
}
onDestroy(() => {
    $detailMilestone = null;
});
</script>

<NotificationDisplay />
{#if isInitializing}
  <div class="_with-air">
    <Spinner />
  </div>
{:else if $detailMilestone}
  <div class="milestoneTable">
    <div class="milestoneTable__header">
      <div class="milestoneTable__projectName">
        <Icon
          class="material-icons"
          style={`color: ${
            $detailMilestone.project.isCommercial ? "green" : "gray"
          };font-size: 16px; margin-right: 2px`}
        >
          monetization_on
        </Icon>
        <a href={"/projects/projectsTab/" + $detailMilestone.project.id} use:link>
          <span>
            {$detailMilestone.project.name}
          </span>
        </a>
      </div>
      <div
        class:milestoneTable__archive--editable={isEditable}
        class="milestoneTable__archive"
        on:click={toggleIsArchived}
      >
        {#if $detailMilestone.isArchived}
          <Icon class="material-icons">sync</Icon>
          <span>Вернуть из архива</span>
        {:else}
          <Icon class="material-icons">inventory</Icon>
          <span>Спрятать в архив</span>
        {/if}
      </div>
    </div>
    <h3 class="milestoneTable__name">
      {$detailMilestone.name}
    </h3>
    <div class="milestoneTable__row">
      <Button
        class="milestoneTable__editBtn"
        disabled={!isEditable}
        on:click={() => changeStatusModal.open()}
        variant="outlined"
      >
        {#if isEditable}
          <Icon class="material-icons">mode_edit</Icon>
        {/if}
        {$detailMilestone.status.name}
      </Button>
      <Button
        class="milestoneTable__editBtn"
        disabled={!isEditable}
        on:click={() => changeBillingTypeModal.open()}
        variant="outlined"
      >
        {#if isEditable}
          <Icon class="material-icons">mode_edit</Icon>
        {/if}
        {$detailMilestone.billingType}
      </Button>
      <Button
        class="milestoneTable__editBtn"
        disabled={!isEditable}
        on:click={() => changeGuaranteeModal.open()}
        variant="outlined"
      >
        {#if isEditable}
          <Icon class="material-icons">mode_edit</Icon>
        {/if}
        {$detailMilestone.isGuarantee ? "Гарантийный" : "Негарантийный"}
      </Button>
    </div>

    <TabBar bind:active let:tab {tabs}>
      <Tab minWidth {tab}>
        <Label>{tab}</Label>
      </Tab>
    </TabBar>

    {#if active === INFO_TAB}
      <div class="milestoneTable__tabContent">
        <div class="milestoneTable__row">
          <div class="milestoneTable__textBlock">
            <span>
              Максимальное кол-во billable часов:
              <span
                class="milestoneTable__editableText"
                class:milestoneTable__editableText--disabled={!isEditable}
                on:click={() =>
                  isEditable
                    ? inputDialogs.find((dial) => dial.id === "maxBillingHours").modalHandler.open()
                    : null}
              >
                {#if isEditable}
                  <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                {/if}
                {$detailMilestone.maxBillingHours || "не задано"}
              </span>
            </span>
          </div>
          <div class="milestoneTable__textBlock">
            <span>
              Планируемая выручка:
              <span
                class="milestoneTable__editableText"
                class:milestoneTable__editableText--disabled={!isEditable}
                on:click={() =>
                  isEditable
                    ? inputDialogs.find((dial) => dial.id === "planRevenue").modalHandler.open()
                    : null}
              >
                {#if isEditable}
                  <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                {/if}

                {$detailMilestone.planRevenue
                  ? formatter.money($detailMilestone.planRevenue)
                  : "не задано"}
              </span>
            </span>
            <span>
              Фактическая выручка:
              <span
                class="milestoneTable__editableText"
                class:milestoneTable__editableText--disabled={!isEditable}
                on:click={() =>
                  isEditable
                    ? inputDialogs.find((dial) => dial.id === "factRevenue").modalHandler.open()
                    : null}
              >
                {#if isEditable}
                  <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                {/if}
                {$detailMilestone.factRevenue
                  ? formatter.money($detailMilestone.factRevenue)
                  : "не задано"}
              </span>
            </span>
          </div>
          <div class="milestoneTable__textBlock">
            <span>
              Начало учета времени
              <span
                class="milestoneTable__editableText"
                class:milestoneTable__editableText--disabled={!isEditable}
                on:click={changeTrackingPeriod.open}
              >
                {#if isEditable}
                  <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                {/if}
                {formatter.date($detailMilestone.tracking_start)}
              </span>
            </span>
            <span>
              Конец учета времени
              <span
                class="milestoneTable__editableText"
                class:milestoneTable__editableText--disabled={!isEditable}
                on:click={changeTrackingPeriod.open}
              >
                {#if isEditable}
                  <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                {/if}

                {formatter.date($detailMilestone.tracking_end)}
              </span>
            </span>
          </div>
          <div class="milestoneTable__textBlock">
            <span>
              Дата завершения
              <span
                class="milestoneTable__editableText"
                class:milestoneTable__editableText--disabled={!isEditable}
                on:click={changeFinishDateModal.open}
              >
                {#if isEditable}
                  <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                {/if}
                {formatter.date($detailMilestone.finish_date)}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="milestoneTable__info">
        <div class="milestoneTable__managerWrapper">
          <Managers
            accountManager={$detailMilestone.project.accountManager}
            projectManager={$detailMilestone.project.projectManager}
          />
        </div>
        {#if $detailMilestone.planProductionProfitability || $detailMilestone.factProductionProfitability}
          <div class="milestoneTable__profitWrapper">
            <Icon class="material-icons" style={`color:gray;font-size: 16px; margin-right: 8px`}>
              monetization_on
            </Icon>
            <div class="milestoneTable__textBlock">
              {#if $detailMilestone.planProductionProfitability}
                <span>
                  Рентабельность пр-ва(план):
                  {$detailMilestone.planProductionProfitability}
                  %
                </span>
              {/if}
              {#if $detailMilestone.factProductionProfitability}
                <span>
                  Рентабельность пр-ва(факт):
                  {$detailMilestone.factProductionProfitability}
                  %
                </span>
              {/if}
            </div>
          </div>
        {/if}
        <div class="milestoneTable__spentHours">
          <div class="milestoneTable__hoursCol">
            <Icon class="material-icons" style={`color:gray;font-size: 16px; margin-right: 8px`}>
              schedule
            </Icon>
            <div class="milestoneTable__textBlock">
              <span
                >Всего часов:
                {$detailMilestone.spentHours.totalHours}</span
              >
              <span
                >Billable часов:
                {$detailMilestone.spentHours.totalBilledHours}</span
              >
            </div>
          </div>
          <div class="milestoneTable__hoursCol">
            <Icon class="material-icons" style={`color:gray;font-size: 16px; margin-right: 8px`}>
              restore
            </Icon>
            <div class="milestoneTable__textBlock">
              <span
                >Часов за прошлую неделю:
                {$detailMilestone.spentHours.lastWeekHours}</span
              >
              <span style="color: {getLastWeekColor()}">
                Billable часов за прошлую неделю:
                {$detailMilestone.spentHours.lastWeekBilledHours}</span
              >
            </div>
          </div>
        </div>
      </div>

      {#if $detailMilestone.planTotalProfitability || $detailMilestone.factTotalProfitability}
        <div class="milestoneTable__row">
          <div class="milestoneTable__profitWrapper">
            <Icon class="material-icons" style={`color:gray;font-size: 16px; margin-right: 8px`}>
              monetization_on
            </Icon>
            <div class="milestoneTable__textBlock">
              {#if $detailMilestone.planTotalProfitability}
                <span>
                  Общая рентабельность(план):
                  {$detailMilestone.planTotalProfitability}
                  %
                </span>
              {/if}
              {#if $detailMilestone.factTotalProfitability}
                <span>
                  Общая рентабельность(факт):
                  {$detailMilestone.factTotalProfitability}
                  %
                </span>
              {/if}
            </div>
          </div>
        </div>
      {/if}
      {#if $detailMilestone.productionCost}
        <div class="milestoneTable__totalCost">
          <h3>
            Себестоимость производства:
            {formatter.money($detailMilestone.productionCost)}
          </h3>
        </div>
      {/if}
      {#if $detailMilestone?.userData.length}
        <DataTable table$aria-label="UserCost">
          <Head>
            <Row>
              <Cell width="200">Имя</Cell>
              <Cell width="200">Себестоимость за последнюю неделю</Cell>
              <Cell width="200">Общая себестоимость</Cell>
              <Cell width="200">Кол-во эффективных часов</Cell>
              <Cell width="200">Кол-во неэффективных часов</Cell>
              <Cell width="200">Кол-во часов «багов»</Cell>
              <Cell width="200">Всего часов</Cell>
            </Row>
          </Head>
          <Body>
            {#each $detailMilestone.userData as user, i (i)}
              <Row>
                <Cell>{user.name}</Cell>
                <Cell>{formatter.money(user.lastWeekCost)}</Cell>
                <Cell>{formatter.money(user.totalCost)}</Cell>
                <Cell>{user.totalEffectiveHours}</Cell>
                <Cell>{user.totalIneffectiveHours}</Cell>
                <Cell>{user.totalBugHours}</Cell>
                <Cell>{user.totalHours}</Cell>
              </Row>
            {/each}
          </Body>
        </DataTable>
      {/if}
      <OtherCosts
        on:success={successCostHandler}
        on:delete={(e) => deleteCostHandler(e.detail.response)}
        costs={{
          milestoneId: $detailMilestone.id,
          additionalCosts: $detailMilestone.additionalCosts,
          fixedCosts: $detailMilestone.fixedCosts,
          otherCosts: $detailMilestone.otherCosts
        }}
      />
    {/if}

    {#if active === CHANGES_TAB}
      <div class="milestoneTable__tabContent">
        <LogsTable params={{ type: "milestone", id: $detailMilestone.id }} />
      </div>
    {/if}

    {#if active === ACCOUNTING}
      <div class="milestoneTable__tabContent">
        <Invoices isAbleToAdd={true} />
      </div>
    {/if}

    {#if active === ACTS_TAB}
      <div class="milestoneTable__tabContent">
        <MilestoneActs milestone={$detailMilestone} />
      </div>
    {/if}
  </div>
{/if}

{#if $statuses}
  <Dialog bind:this={changeStatusModal}>
    <Title>Выберите статус</Title>
    <div class="milestoneTable__contentWrapper">
      <Select
        anchor$class="milestoneTable__select"
        bind:value={selectedStatusId}
        class="milestoneTable__select"
        label="Статус"
        menu$class="milestoneTable__selectMenu"
      >
        {#each $statuses as { id, name }}
          <Option value={id.toString()}>{name}</Option>
        {/each}
      </Select>
    </div>
    <footer class="milestoneTable__dialogFooter">
      <Button disabled={buttonDisabler} on:click={() => saveHandler("status")}>Сохранить</Button>
    </footer>
  </Dialog>
{/if}

<Dialog bind:this={changeFinishDateModal}>
  <Title>Выберите дату завершения</Title>
  <div class="milestoneTable__contentWrapper">
    <Textfield
      type="date"
      bind:value={finishDateValue}
      on:input={(e) => {
        finishDateValue = e.target.value;
      }}
    />
  </div>
  <h2>
    <div />
  </h2>
  <div class="milestoneTable__dialogFooter">
    <Button disabled={buttonDisabler} on:click={() => saveHandler("finish_date")}>Сохранить</Button>
  </div>
</Dialog>

<Dialog bind:this={changeTrackingPeriod}>
  <Title>Выберите даты учета времени</Title>
  <div class="milestoneTable__contentWrapper milestoneTable__contentWrapper_ads">
    <div>
      <div>Начало</div>
      <Textfield
      type="date"
      bind:value={trackingStartValue}
      on:input={(e) => {
        trackingStartValue = e.target.value;
      }}
      />
    </div>
    <div>
      <div>Конец</div>
      <Textfield
        type="date"
        bind:value={trackingEndValue}
        on:input={(e) => {
          trackingEndValue = e.target.value;
        }}
      />
    </div>
  </div>
  <h2>
    <div />
  </h2>
  <div class="milestoneTable__dialogFooter">
    <Button disabled={buttonDisabler} on:click={() => saveHandler("tracking_period")}>Сохранить</Button>
  </div>
</Dialog>

<Dialog bind:this={changeBillingTypeModal}>
  <Title>Выберите тип биллинга</Title>
  <div class="milestoneTable__contentWrapper">
    <Select
      anchor$class="milestoneTable__select"
      bind:value={selectedBillingType}
      class="milestoneTable__select"
      label="Тип биллинга"
      menu$class="milestoneTable__selectMenu"
    >
      {#each availableBillingTypes as billingType}
        <Option value={billingType}>{billingType}</Option>
      {/each}
    </Select>
  </div>
  <h2>
    <div />
  </h2>
  <footer class="milestoneTable__dialogFooter">
    <Button disabled={buttonDisabler} on:click={() => saveHandler("billingType")}>Сохранить</Button>
  </footer>
</Dialog>

<Dialog bind:this={changeGuaranteeModal}>
  <Title>Выберите значение гарантийности</Title>
  <div class="milestoneTable__contentWrapper">
    <Select
      anchor$class="milestoneTable__select"
      bind:value={selectedGuaranteeValue}
      class="milestoneTable__select"
      label="Значение гарантийности"
      menu$class="milestoneTable__selectMenu"
    >
      {#each availableGuaranteeValues as { label }}
        <Option value={label}>{label}</Option>
      {/each}
    </Select>
  </div>
  <footer class="milestoneTable__dialogFooter">
    <Button disabled={buttonDisabler} on:click={() => saveHandler("isGuarantee")}>Сохранить</Button>
  </footer>
</Dialog>

{#if inputDialogs}
  {#each inputDialogs as { id, modalTitle, value, modalHandler } (id)}
    <Dialog bind:this={modalHandler}>
      <Title>{modalTitle}</Title>
      <div class="milestoneTable__contentWrapper">
        <Textfield type="number" bind:value input$min="0" />
      </div>
      <footer class="milestoneTable__dialogFooter">
        <Button disabled={buttonDisabler} on:click={() => saveHandler(id, value)}>Сохранить</Button>
      </footer>
    </Dialog>
  {/each}
{/if}

<style lang="scss">.milestoneTable__header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.milestoneTable__archive {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.milestoneTable__archive--editable:hover {
  cursor: pointer;
}
.milestoneTable__archive--editable:hover span {
  text-decoration: underline;
}
.milestoneTable__projectName {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.milestoneTable__row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}
.milestoneTable__tabContent {
  margin-top: 26px;
}
.milestoneTable__name {
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 46px;
}
.milestoneTable :global(.milestoneTable__editBtn) {
  margin-right: 30px;
}
.milestoneTable__info {
  display: flex;
  margin-bottom: 26px;
}
.milestoneTable__managerWrapper {
  margin-right: 40px;
}
.milestoneTable__profitWrapper {
  display: flex;
  margin-right: 40px;
}
.milestoneTable__textBlock {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-right: 30px;
}
.milestoneTable__textBlock > span {
  display: block;
}
.milestoneTable__spentHours {
  display: flex;
  width: 500px;
}
.milestoneTable__hoursCol {
  display: flex;
}
.milestoneTable__editableText {
  margin-left: 6px;
  color: #6200ee;
  text-decoration: underline;
}
.milestoneTable__editableText:hover {
  cursor: pointer;
}
.milestoneTable__editableText--disabled {
  text-decoration: none;
}
.milestoneTable__editableText--disabled:hover {
  cursor: auto;
}
.milestoneTable__contentWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
}
.milestoneTable__contentWrapper_ads {
  flex-direction: row;
  gap: 20px;
}
.milestoneTable :global(&__select) {
  width: 100%;
}
.milestoneTable :global(&__selectMenu) {
  width: 100%;
}
.milestoneTable__dialogFooter {
  padding: 0 15px 15px;
  display: flex;
  justify-content: flex-end;
}
.milestoneTable__totalCost {
  margin-bottom: 12px;
}</style>
