<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from "svelte";
import { formatter } from "helpers/formatter";
import debounce from "lodash.debounce";
import { link, push } from "svelte-spa-router";
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import Button, { Label } from "@smui/button";
import { Icon } from "@smui/common";
import TextInput from "../../shared/Fields/Text.svelte";
import CoinsTransactions from "./CoinsTransactions/index.svelte";
import CoinModal from "./CoinModal/index.svelte";
import Spinner from "../../shared/Spinner/index.svelte";
import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
import { authStore } from "stores/authStore";
import { usersStore } from "stores/usersStore";
import { modalPanelStore } from "stores/modalPanelStore";
;
export let params;
const { hasPermission } = authStore;
const { getUsersCoins, saveUserTransaction, usersCoins, isFetchBalance, isSaveTransaction } = usersStore;
const { component, componentProps, isOpen } = modalPanelStore;
let filteredUsersCoins;
let isModalOpen = false;
let saveError = "";
$: filteredUsersCoins = $usersCoins;
onMount(() => {
    if (!hasPermission([
        "coins_read_all",
        "coins_update",
        "coins_full_access",
        "coins_delete",
        "coins_add"
    ]))
        push("/");
    else {
        getUsersCoins();
    }
});
const debouncedSearch = debounce(handleSearch, 300);
function handleSearch(e) {
    const str = e.detail.toLowerCase();
    //@ts-ignore
    filteredUsersCoins = $usersCoins ? $usersCoins === null || $usersCoins === void 0 ? void 0 : $usersCoins.filter((balance) => balance.name.toLowerCase().indexOf(str) !== -1) : [];
}
function manageModalPanel({ id }) {
    if (id) {
        componentProps.set({ userId: Number(id) });
        component.set(CoinsTransactions);
        isOpen.set(true);
    }
    if (!id) {
        isOpen.set(false);
    }
}
function saveHandler(transactionToSave) {
    return __awaiter(this, void 0, void 0, function* () {
        const { message, success } = yield saveUserTransaction(transactionToSave);
        if (!success) {
            saveError = message;
        }
        else {
            yield getUsersCoins();
            saveError = "";
            notifier.success(message);
            isModalOpen = false;
        }
    });
}
$: if (params)
    manageModalPanel(params);
</script>

<NotificationDisplay />
<div class="coins">
  <div class="coins__row">
    <h1>Лицевые счета</h1>
    <div class="coins__inputWrapper">
      <TextInput label="Поиск пользователя" on:input={debouncedSearch} />
    </div>
    {#if hasPermission(["coins_update", "coins_add", "coins_full_access"])}
      <Button variant="unelevated" on:click={() => (isModalOpen = true)}>
        <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 16px 8px 0`}>
          add
        </Icon>
        <Label>Добавить транзакцию</Label>
      </Button>
    {/if}
  </div>
  {#if filteredUsersCoins && filteredUsersCoins?.length}
    <div class="coins__table">
      <DataTable table$aria-label="UsersCoins">
        <Head>
          <Row>
            <Cell width="200px">Пользователь</Cell>
            <Cell width="200px">Баланс</Cell>
            <Cell width="200px" />
          </Row>
        </Head>
        <Body>
          {#each filteredUsersCoins as { name, coins, id }}
            <Row>
              <Cell>{name}</Cell>
              <Cell>{formatter.coins(coins)}</Cell>
              <Cell><a href={`/coins/${id}`} use:link>Транзакции</a></Cell>
            </Row>
          {/each}
        </Body>
      </DataTable>
    </div>
  {:else if $isFetchBalance}
    <Spinner />
  {:else}
    Ничего не найдено
  {/if}
</div>

<CoinModal
  selectedUserId={undefined}
  {isModalOpen}
  isSaveTransaction={$isSaveTransaction}
  usersCoins={$usersCoins ?? []}
  error={saveError}
  on:save={(e) => saveHandler(e.detail)}
  on:close={() => (isModalOpen = false)}
/>


<style lang="scss">.coins {
  padding: 40px 30px 40px 0;
}
.coins__row {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.coins__row h1 {
  margin-right: 10px;
}
.coins__inputWrapper {
  margin-right: 20px;
}
.coins__table {
  width: 800px;
}</style>
