<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from "svelte";
import Button, { Label } from "@smui/button";
import Textfield from "@smui/textfield";
import Dialog from "./Dialog/index.svelte";
import { Icon } from "@smui/common";
import { companiesStore } from "stores/companiesStore";
import Table from "./Table/index.svelte";
import { checkINN } from "ru-validation-codes";
;
import { vatTypesStore } from "stores/vatTypesStore";
const { companyBySuggestion, getCompanies, getCompanyBySuggestion } = companiesStore;
const { getVatTypes } = vatTypesStore;
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    yield Promise.all([getCompanies(), getVatTypes()]);
}));
let isValidInn = false;
let label = "ИНН контрагента";
let inn = "";
let editInn = "";
let innInput;
let selectedCompanyType = 1;
let selectedVatType = null;
let isOurCompany = false;
let companyModal;
let errors = {};
let isEditMode = false;
let editingCompanyId = null;
const changeInn = (e) => {
    const value = e.target.value;
    isValidInn = !checkINN(value);
    if (checkINN(value)) {
        label = "ИНН контрагента";
        inn = value;
    }
    else {
        label = "Невалидный формат ИНН";
    }
};
const parseByInn = () => {
    if (checkINN(inn)) {
        getCompanyBySuggestion(inn);
        companyModal === null || companyModal === void 0 ? void 0 : companyModal.open();
    }
    else {
        innInput.focus();
    }
};
const companyModalClose = () => {
    companyModal === null || companyModal === void 0 ? void 0 : companyModal.close();
    isEditMode = false;
    errors = {};
    editingCompanyId = null;
    editInn = "";
    $companyBySuggestion = {};
};
</script>

<div class="companies">
  <div class="companies__inn-form">
    <Textfield
      style="width: 250px; max-height: 38px;"
      bind:value={inn}
      bind:this={innInput}
      {label}
      invalid={isValidInn}
      input$placeholder="Введите ИНН"
      type="text"
      on:input={changeInn}
    />

    <Button variant="unelevated" on:click={parseByInn}>
      <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 16px 8px 0`}>
        add
      </Icon>
      <Label>Добавить контрагента</Label>
    </Button>
  </div>

  <div class="companies__table">
    <Table
      bind:isOurCompany
      bind:isEditMode
      bind:editingCompanyId
      {companyModal}
      {companyModalClose}
      bind:selectedCompanyType
      bind:selectedVatType
      bind:inn={editInn}
    />
  </div>

  <Dialog
    bind:errors
    {inn}
    {editInn}
    bind:isOurCompany
    bind:isEditMode
    bind:editingCompanyId
    bind:companyModal
    {companyModalClose}
    bind:selectedCompanyType
    bind:selectedVatType
  />
</div>

<style lang="scss">.companies {
  margin-top: 15px;
}
.companies__table {
  margin-top: 15px;
}
.companies__inn-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 550px;
  max-width: 570px;
}
.companies :global(.mdc-dialog__surface) {
  min-width: 700px;
  max-height: 80vh;
}</style>
