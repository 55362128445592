<script>
  import SelectUser from "../../../shared/SelectUser/index.svelte"

  import { daysOffStore } from "stores/daysOffStore";

  const {
    filters,
    updateFilters,
    currentPage,
    defaultFilters
  } = daysOffStore

  const handleSelectFilterID = (event) => {
    currentPage.set(1)
    updateFilters({...defaultFilters, user_id: event.detail.id })
  }
  const handleClear = () => {
    currentPage.set(1)
    delete $filters.user_id
    updateFilters(defaultFilters)
  }
</script>

<div class="filters">
  <h3 class="mdc-typography--headline5">Фильтры:</h3>
  <SelectUser on:select={handleSelectFilterID} on:clear={handleClear}/>
</div>

<style lang="scss">.filters {
  margin-bottom: 15px;
  max-width: 300px;
}
.filters h3 {
  margin-bottom: 5px;
}</style>
