<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount, createEventDispatcher } from "svelte";
import Checkbox from "@smui/checkbox";
import FormField from "@smui/form-field";
import Select from "../CustomSelect/index.svelte";
import { projectsStore } from "stores/projectsStore";
import { showArchiveStore } from "stores/showArchivedStore";
;
;
//дефолтные значения нужно передавать через prop selectedValue в этом компоненте,
// так сделано, чтобы в селекторы можно передавать было дефолтные параметры
export let selectedValue = undefined;
const { getProjects, projects } = projectsStore;
const { showArchivedFlag } = showArchiveStore;
const dispatch = createEventDispatcher();
let selectedProject = undefined;
let selectedMilestone = undefined;
const handleSelectProject = (e) => __awaiter(void 0, void 0, void 0, function* () {
    selectedProject = e.detail;
    selectedMilestone = undefined;
    dispatch("changeValue", null);
});
const handleSelectMilestone = (e) => {
    selectedMilestone = e.detail;
    dispatch("changeValue", e.detail.id);
};
function handleCheckbox(e) {
    const checked = e.target.checked;
    showArchiveStore.setShowArchived(checked);
    getProjects();
}
let noOptionsMessage = "Не удалось загрузить данные";
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!$projects) {
        try {
            yield getProjects();
        }
        catch (e) {
            noOptionsMessage = e;
        }
    }
    selectedProject = $projects === null || $projects === void 0 ? void 0 : $projects.find((project) => { var _a; return project.id === ((_a = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.project) === null || _a === void 0 ? void 0 : _a.id); });
    if (selectedProject) {
        selectedMilestone = (_a = selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.milestones) === null || _a === void 0 ? void 0 : _a.find((item) => { var _a; return item.id === ((_a = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.milestone) === null || _a === void 0 ? void 0 : _a.id); });
    }
}));
$: projectList = $projects === null || $projects === void 0 ? void 0 : $projects.filter((projects) => { var _a; return (_a = projects === null || projects === void 0 ? void 0 : projects.milestones) === null || _a === void 0 ? void 0 : _a.length; });
</script>

<p style="background: #E4E4E4">
  <FormField>
    <Checkbox checked={$showArchivedFlag} on:change={handleCheckbox}/>
    <span slot="label">Показывать вехи архивных проектов </span>
  </FormField>
</p>
<label for="">
  <Select
    {noOptionsMessage}
    items={projectList ?? []}
    isClearable={false}
    placeholder="Проект"
    on:select={handleSelectProject}
    selectedValue={selectedProject}
  />
</label>
<p/>
<label for="">
  <Select
    noOptionsMessage="Выберите проект"
    items={selectedProject?.milestones ?? []}
    isClearable={false}
    placeholder="Веха*"
    on:select={handleSelectMilestone}
    selectedValue={selectedMilestone}
  />
</label>

<style lang="scss">p {
  margin-bottom: 10px;
}</style>
