<script lang="ts">import { onMount } from "svelte";
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import Dialog, { Title, Content } from "@smui/dialog";
import Button from "@smui/button";
import Paper from "@smui/paper";
import { authStore } from "stores/authStore";
import { paymentStore } from "stores/paymentStore";
import Header from "../../shared/Header/index.svelte";
import Spinner from "../../shared/Spinner/index.svelte";
import IsDone from "../../shared/IsDone/index.svelte";
import Chat from "./Chat/index.svelte";
const { userData } = authStore;
const { headCells, isLoading, paymentInformation, getPaymentInformation, putApprovePayment } = paymentStore();
let isApproveModalOpened;
const cancel = () => {
    isApproveModalOpened.close();
};
const approve = () => {
    putApprovePayment(id);
    isApproveModalOpened.close();
};
onMount(() => {
    getPaymentInformation(id);
});
export let params;
const id = parseInt(params.id);
</script>

<section class="section users payment-section">
  {#if $isLoading}
    <div class="spinner-container">
      <Spinner />
    </div>
  {:else}
    <Header
      title={`Реестр #${$paymentInformation?.registry.id} (${$paymentInformation?.registry.date_from} - ${$paymentInformation?.registry.date_to}), ${$userData?.name}`}
    />
    <div class="billing-container">
      <Paper>
        <h3>Формат оплаты: {$paymentInformation?.user_billing_type}</h3>
        {#if $paymentInformation?.user_billing_type === "TM"}
          <h3>Эффективные часы: {$paymentInformation?.hours.effective}</h3>
          <h3>Неэффективные часы: {$paymentInformation?.hours.ineffective}</h3>
          <h3>Сверхурочные часы: {$paymentInformation?.hours.overtime}</h3>
          <h3>Итого часов: {$paymentInformation?.hours.total}</h3>
          <h3>Баги: {$paymentInformation?.hours.bugs}</h3>
        {/if}
      </Paper>
    </div>
    <div class="coins__table">
      <DataTable>
        <Head>
          <Row>
            {#each headCells as cell}
              <Cell width="200px">{cell}</Cell>
            {/each}
          </Row>
        </Head>
        <Body>
          <Row>
            <Cell>{$paymentInformation?.adjustment || ""}</Cell>
            <Cell>{$paymentInformation?.adjustment_comment}</Cell>
            <Cell> <div class="calc">
              <b>{$paymentInformation?.calculated_sum} </b>
              {#if $paymentInformation?.auto_adjustments?.length}
                {#each $paymentInformation?.auto_adjustments as item (item.id)}
                  <i> {item.value} ({item.name}) </i>
                {/each}
                {/if}

            </div> </Cell>
            <Cell><b>{$paymentInformation?.total_sum}</b></Cell>
            <Cell><b>{$paymentInformation?.noncash?.sum || 0}</b></Cell>
            <Cell><b>{$paymentInformation?.cash?.sum || 0}</b></Cell>
            <Cell>
              {#if $paymentInformation?.is_approved}
                <span class="done">Да ({$paymentInformation.approved_by.name})</span>
              {:else}
                <span class="in_process" on:click={() => isApproveModalOpened.open()}
                  >Нет (Подтвердить)
                </span>
              {/if}
            </Cell>
            <Cell>
              <IsDone isDone={$paymentInformation?.noncash?.is_paid} />
            </Cell>
            <Cell>
              <IsDone isDone={$paymentInformation?.cash?.is_paid} />
            </Cell>
          </Row>
        </Body>
      </DataTable>
    </div>
  {/if}
  <Chat {id} />
</section>
<Dialog bind:this={isApproveModalOpened}>
  <Title>Подтверждение действия</Title>
  <Content>Вы уверены, что хотите совершить операцию?</Content>
  <footer class="buttons">
    <Button on:click={approve}>Да</Button>
    <Button on:click={cancel}>Нет</Button>
  </footer>
</Dialog>

<style lang="scss">.billing-container {
  margin-bottom: 20px;
  max-width: 250px;
}

.calc {
  display: flex;
  flex-direction: column;
}

b {
  font-weight: bold;
  margin-bottom: 5;
}

i {
  font-style: italic;
}

.payment-section {
  position: relative;
}
.payment-section .done {
  color: #9dbd86;
}
.payment-section .in_process {
  color: #c5a656;
  cursor: pointer;
}

.buttons {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 15px;
}

:global(._with-air) {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}</style>
