<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from "svelte";
import { invoicesStore } from "stores/invoicesStore";
import InvoiceControls from "./InvoiceControls/index.svelte";
import InvoiceModal from "./InvoiceModal/index.svelte";
import InvoicesTable from "./InvoicesTable/index.svelte";
const { getInvoiceStatuses, invoice, invoices, setDefaultFields, currentPositions, editId, emptyPosition, getInvoices, } = invoicesStore;
let modal;
const handleModalClose = () => {
    modal.close();
    $editId = null;
    $invoice = setDefaultFields($invoice);
    $currentPositions = [JSON.parse(JSON.stringify($emptyPosition))];
};
const handleModalOpen = () => {
    modal.open();
};
const handleSubmitInvoice = () => {
    handleModalOpen();
};
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    yield getInvoiceStatuses();
    getInvoices();
}));
</script>

<div class="invoices">
  <InvoiceControls isAbleToAdd hasFilters onCreate={handleSubmitInvoice} />
  <InvoiceModal bind:modal onModalClose={handleModalClose} />
  <div class="invoices__table">
    <InvoicesTable onModalOpen={handleModalOpen} />
  </div>
  {#if $invoices?.meta?.total && typeof $invoices?.meta?.sum === 'number'}
    <div class="invoices__meta">Всего {$invoices?.meta?.total} счетов на сумму {$invoices?.meta?.sum?.toFixed(2)?.toLocaleString()} рублей.</div>
  {/if}
</div>

<style lang="scss">.invoices {
  margin-top: 15px;
}
.invoices__meta {
  margin-top: 10px;
}
.invoices__table {
  margin-top: 15px;
}
.invoices :global(.mdc-dialog__surface) {
  min-width: 700px;
  max-height: 80vh;
}</style>
