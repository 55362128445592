<script lang="typescript">import cn from "classnames";
export let size = 30;
export let childClass = "";
const currentClass = cn("spinner", $$props.class);
const style = `height: ${size}px; width: ${size}px;`;
</script>

<div class={currentClass} style={style}>
  {#each Array(12).fill(undefined) as _, i}
    <div class={cn("child", `circle${i + 1}`, childClass)} />
  {/each}
</div>

<style lang="scss">.spinner {
  position: relative;
}

.spinner .child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.spinner .child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #9d30c5;
  border-radius: 100%;
  -webkit-animation: circleBounceDelay 1.2s infinite ease-in-out both;
          animation: circleBounceDelay 1.2s infinite ease-in-out both;
}

.spinner > .circle2 {
  transform: rotate(30deg);
}
.spinner > .circle2::before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.spinner > .circle3 {
  transform: rotate(60deg);
}
.spinner > .circle3::before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.spinner > .circle4 {
  transform: rotate(90deg);
}
.spinner > .circle4::before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.spinner > .circle5 {
  transform: rotate(120deg);
}
.spinner > .circle5::before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.spinner > .circle6 {
  transform: rotate(150deg);
}
.spinner > .circle6::before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.spinner > .circle7 {
  transform: rotate(180deg);
}
.spinner > .circle7::before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.spinner > .circle8 {
  transform: rotate(210deg);
}
.spinner > .circle8::before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.spinner > .circle9 {
  transform: rotate(240deg);
}
.spinner > .circle9::before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.spinner > .circle10 {
  transform: rotate(270deg);
}
.spinner > .circle10::before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.spinner > .circle11 {
  transform: rotate(300deg);
}
.spinner > .circle11::before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.spinner > .circle12 {
  transform: rotate(330deg);
}
.spinner > .circle12::before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}</style>
