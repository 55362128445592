<script lang="typescript">import Spinner from "./index.svelte";
export let size;
export let isShow;
</script>

{#if isShow}
  <div class="fullSideSpinnerWrapper">
    <Spinner {size} class="fullSideSpinner" />
  </div>
{/if}

<style lang="scss">.fullSideSpinnerWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  background-color: #fff;
  z-index: 5;
}

:global(.fullSideSpinner) {
  margin: auto;
}</style>
