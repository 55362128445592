<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
;
import InvoicesTable from "../../../Accounting/Invoices/InvoicesTable/index.svelte";
import InvoiceControls from "../../../Accounting/Invoices/InvoiceControls/index.svelte";
import InvoiceModal from "../../../Accounting/Invoices/InvoiceModal/index.svelte";
import { onDestroy, onMount } from "svelte";
import { invoicesStore } from "stores/invoicesStore";
import { DEFAULT_LENGTH_LIST } from "constants/constants";
;
import { projectsStore } from "stores/projectsStore";
const { detailProject } = projectsStore;
const { getInvoiceStatuses, getInvoices, invoice, setDefaultFields, currentPositions, editId, filters, emptyPosition } = invoicesStore;
let modal;
const handleModalClose = () => {
    modal === null || modal === void 0 ? void 0 : modal.close();
    $editId = null;
    $invoice = setDefaultFields($invoice);
    $currentPositions = [JSON.parse(JSON.stringify($emptyPosition))];
};
const handleModalOpen = () => {
    const milestone = $invoice.find((item) => item.slug === "milestone");
    if (milestone) {
        milestone.disabled = true;
    }
    $invoice = $invoice;
    modal === null || modal === void 0 ? void 0 : modal.open();
};
let isLoading = true;
const handleSubmitInvoice = () => {
    handleModalOpen();
};
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    filters.update(() => ({
        limit: DEFAULT_LENGTH_LIST,
        offset: 0,
        search: "",
        status_id: "",
        project_id: $detailProject === null || $detailProject === void 0 ? void 0 : $detailProject.id,
        milestone_id: undefined,
    }));
    yield getInvoices();
    yield getInvoiceStatuses();
    isLoading = false;
}));
onDestroy(() => {
    filters.update(() => ({
        limit: DEFAULT_LENGTH_LIST,
        offset: 0,
        search: "",
        status_id: "",
        project_id: undefined,
        milestone_id: undefined
    }));
});
</script>

<div class="invoices">
  <InvoiceControls isAbleToAdd={false} hasFilters={false} onCreate={handleSubmitInvoice} />
  {#if !isLoading}<InvoiceModal bind:modal onModalClose={handleModalClose} />{/if}
  <div class="invoices__table">
    <InvoicesTable onModalOpen={handleModalOpen} />
  </div>
</div>

<style lang="scss">.invoices {
  margin-top: 15px;
}
.invoices__table {
  margin-top: 15px;
}
.invoices :global(.mdc-dialog__surface) {
  min-width: 700px;
  max-height: 80vh;
}</style>
