<script lang="ts">import { createEventDispatcher, onMount } from 'svelte';
import Select from "../CustomSelect/index.svelte";
import { projectsStore } from "stores/projectsStore";
export let placeholder = "Выберите проект";
/**
 * передавать не обязательно, только если нужна возможность очистить выбранное значение
 * */
export let selectedValue = undefined;
export const isClearable = true;
const dispatch = createEventDispatcher();
const { getProjects, projects, errorMessage } = projectsStore;
function handleSelect(event) {
    dispatch('select', {
        id: event.detail.id,
        name: event.detail.name
    });
}
onMount(() => {
    if (!($projects === null || $projects === void 0 ? void 0 : $projects.length)) {
        getProjects()
            .catch(errorMessage.set);
    }
});
</script>

{#if !$errorMessage}
  <div class="select-project">
    <label for="">
      <Select items={$projects ?? []}
              {isClearable}
              {placeholder}
              {selectedValue}
              on:select={handleSelect}
              on:clear
              listAutoWidth={false}
      />

    </label>
  </div>
{/if}

<style lang="scss">.select-project {
  min-width: 250px;
  padding-bottom: 5px;
}</style>
