<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from "svelte";
import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
import Button from '@smui/button';
import Menu from '@smui/menu';
import IconButton from "@smui/icon-button";
import List, { Item, Text } from '@smui/list';
import Spinner from "../../../shared/Spinner/index.svelte";
import { notificationsStore } from "stores/notificationsStore";
;
const { notifications, notificationsCount, notificationsUnreadCount, getNotifications, setAsReadNotifications, getMoreNotifications, isLoadNotifications } = notificationsStore;
let readedNotifications = [];
// счётчик непрочитанных сообщений - если отличается от notificationsUnreadCount - вызывается notifier с звуком
let currentUnreadCount = 0;
const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
    menu.setOpen(true);
    if ($notificationsUnreadCount) {
        const getUnreadId = (acc, curr) => {
            if (!curr.isRead)
                acc.push(curr.id);
            return acc;
        };
        const unreadMessages = $notifications.reduce(getUnreadId, []);
        const success = yield setAsReadNotifications(unreadMessages);
        if (success) {
            const setRead = (item) => (Object.assign(Object.assign({}, item), { isRead: true }));
            readedNotifications = $notifications.map(setRead);
        }
    }
});
const DEFAULT_COUNT_SEC = 30;
const DEFAULT_INTERVAL = DEFAULT_COUNT_SEC * 1000;
let menu;
let open = false;
const soundObj = new Audio("/sounds/alert.mp3");
const notifyBySound = () => __awaiter(void 0, void 0, void 0, function* () {
    yield soundObj.play();
});
const updateNotificationInStore = (readedNotifications, open) => {
    if ((readedNotifications === null || readedNotifications === void 0 ? void 0 : readedNotifications.length) && !open) {
        $notificationsUnreadCount = 0;
        currentUnreadCount = 0;
        $notifications = readedNotifications;
        readedNotifications = [];
    }
};
$: updateNotificationInStore(readedNotifications, open);
onMount(() => {
    getNotifications();
    const interval = setInterval(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!open) {
            yield getNotifications();
            if ($notificationsUnreadCount !== currentUnreadCount) {
                yield notifyBySound();
                notifier.info(`У вас есть непрочитанные уведомления`);
                currentUnreadCount = $notificationsUnreadCount;
            }
        }
    }), DEFAULT_INTERVAL);
    return () => clearInterval(interval);
});
$: title = $notificationsUnreadCount ? `(${$notificationsUnreadCount}) Yo! Digital Spectr` : `Yo! Digital Spectr`;
$: {
    document.title = title;
}
</script>

<NotificationDisplay />
<span class="notifications"
      class:_hasNotification={$notificationsUnreadCount}
      data-count-notifications={$notificationsUnreadCount}
      on:click={handleClick}>

          <IconButton class="material-icons topBarIcon">notifications</IconButton>
          <Menu bind:open bind:this={menu} class="notifications__menu" setDefaultFocusState={() => {}} wrapFocus={true}>
           <ul class="notifications-list">
             <h3>Уведомления</h3>
              <List nonInteractive>
              {#each $notifications as not (not.id)}
             <Item class={`notifications-list__item ${not.isRead ? "" : "_unread"}`}>
                 	<Text>{@html not.formattedData}</Text>
             </Item>
             {/each}
              </List>

             {#if $notificationsCount > $notifications.length}
               <div class="get-more-wrap">
                        <Button class="get-more"
                                variant="raised"
                                on:click={getMoreNotifications}>
                          Загрузить еще
                        </Button>
                 {#if $isLoadNotifications}
                         <Spinner/>
                       {/if}
               </div>
             {/if}
           </ul>
          </Menu>
      </span>

<style lang="scss">.notifications :global(.mdc-menu-surface.notifications__menu) {
  right: 0px !important;
  top: 40px !important;
  left: auto !important;
  transform-origin: right top !important;
  width: 350px !important;
}
.notifications .get-more-wrap {
  display: flex;
  justify-content: center;
}
.notifications :global(.get-more) {
  margin-right: 10px;
  padding: 10px 15px;
}
.notifications .notifications-list {
  display: flex;
  flex-direction: column;
  max-height: 473px;
  overflow-y: auto;
}
.notifications .notifications-list::-webkit-scrollbar {
  width: 10px;
}
.notifications .notifications-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.notifications .notifications-list::-webkit-scrollbar-thumb {
  background: #888;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 10px;
}
.notifications .notifications-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.notifications .notifications-list h3 {
  text-align: center;
  padding-top: 10px;
}
.notifications .notifications-list :global(.notifications-list__item) {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  height: auto;
  font-size: 13px;
  line-height: 19px;
  padding: 5px 16px;
}
.notifications .notifications-list :global(.notifications-list__item) :global(span) {
  white-space: unset;
}
.notifications .notifications-list :global(.notifications-list__item._unread) {
  position: relative;
}
.notifications .notifications-list :global(.notifications-list__item._unread:before) {
  content: "";
  position: absolute;
  top: 7px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--red);
}

._hasNotification {
  position: relative;
  cursor: pointer;
}
._hasNotification:after {
  margin-left: 10px;
  content: attr(data-count-notifications);
  top: 11px;
  height: 12px;
  padding: 2px 4px;
  left: 17px;
  color: white;
  font-size: 11px;
  background-color: var(--red);
  border-radius: 12px;
  position: absolute;
}</style>
