<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Dialog, { Title } from "@smui/dialog";
import Button from "@smui/button";
import { notifier } from "@beyonk/svelte-notifications";
import Spinner from "../../../../shared/Spinner/index.svelte";
;
;
import { apiPostInvoiceFile } from "api/methods/invoices/apiPostInvoiceFile";
export let item;
export let modal;
const modalClose = () => modal === null || modal === void 0 ? void 0 : modal.close();
let isLoading = false;
const handleDownload = (is_signed) => __awaiter(void 0, void 0, void 0, function* () {
    if (item) {
        isLoading = true;
        const { message, success } = yield apiPostInvoiceFile(item.id, is_signed);
        if (success) {
            window.open(message.file_link);
            modalClose();
        }
        else {
            notifier.danger(message, 2000);
        }
        isLoading = false;
    }
});
</script>

<Dialog bind:this={modal} on:MDCDialog:closed={modalClose}>
  <Title id="simple-title">Скачивание счёта</Title>
  <div class="buttons">
    {#if isLoading}
      <Spinner />
    {:else}
      <div class="controls">
        <Button variant="outlined" on:click={() => handleDownload(true)}>С печатью</Button>&nbsp;
        <Button on:click={() => handleDownload(false)}>Без печати</Button>
      </div>
    {/if}

    <Button color="secondary" on:click={modalClose}>Отмена</Button>
  </div>
</Dialog>

<style lang="scss">.buttons {
  margin: 20px;
  display: flex;
  justify-content: space-between;
}</style>
