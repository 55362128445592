<script lang="typescript">import Textfield from "@smui/textfield";
import Button, { Label } from "@smui/button";
import { Icon } from "@smui/common";
import { invoicesStore } from "stores/invoicesStore";
import Select from "svelte-select";
import { debounce, toSelectItems } from "helpers/helpers";
import { DEFAULT_STATUSES } from "constants/constants";
export let onCreate;
export let hasFilters;
export let isAbleToAdd;
let label = "Фильтр по счёту";
const { statuses, filters, getInvoices } = invoicesStore;
$: value = toSelectItems($statuses.filter((item) => { var _a; return $filters.status_id ? (_a = $filters.status_id) === null || _a === void 0 ? void 0 : _a.split(",").includes(item.id.toString()) : false; }), "name", "id");
const handleChange = debounce((e) => {
    filters.update((data) => (Object.assign(Object.assign({}, data), { search: e.target.value })));
    getInvoices();
}, 500);
const handleSelect = (e) => {
    const statuses = e.detail;
    const labels = (statuses === null || statuses === void 0 ? void 0 : statuses.map((item) => item.label)) || [];
    localStorage.setItem("statuses", JSON.stringify(labels));
    filters.update((data) => (Object.assign(Object.assign({}, data), { status_id: (statuses === null || statuses === void 0 ? void 0 : statuses.map((item) => item.value).join(",")) || "" })));
    getInvoices();
};
</script>

<div class={`form ${!hasFilters ? "end" : ""}`}>
  {#if hasFilters}
    <div class="input">
      <Textfield bind:label bind:value={$filters.search} type="text" on:input={handleChange} />
    </div>

    <div class="input">
      <Select
        isMulti
        items={toSelectItems($statuses, "name", "id")}
        selectedValue={value}
        placeholder="Статус"
        on:select={handleSelect}
        showIndicator={false}
      />
    </div>
  {/if}

  {#if isAbleToAdd}
    <Button on:click={onCreate} variant="unelevated">
      <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 16px 8px 0`}>
        add
      </Icon>
      <Label>ДОБАВИТЬ СЧЕТ</Label>
    </Button>
  {/if}
</div>

<style lang="scss">.form {
  display: flex;
  align-items: flex-end;
}

.end {
  justify-content: flex-end;
}

.input {
  width: 250px;
  margin-right: 40px;
}

:global(.mdc-text-field) {
  width: 100%;
}</style>
