<script lang="ts">var _a;
;
import Cell from "./Cell/index.svelte";
export let statuses;
export let invoice;
const statusesKeys = Object.keys(statuses);
const status = (_a = invoice.status) === null || _a === void 0 ? void 0 : _a.name;
let currentStatus = status && statuses[status];
let color = currentStatus.color;
const width = `${100 / statusesKeys.length}%`;
</script>

<div class="outer">
  <div class="progress">
    {#each statusesKeys as statusKey}
      <Cell
        style={`width: ${width}`}
        isFilled={statuses[statusKey].value <= currentStatus.value}
        description={statuses[statusKey].description}
        bind:color
        bind:invoice
        isCurrent={statusKey === status}
        bind:status_id={statuses[statusKey].id}
        on:click
      />
    {/each}
  </div>
  <span class="status">{status}</span>
</div>


<style lang="scss">.outer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.progress {
  min-width: 200px;
  display: flex;
  height: 10px;
  border: 1px solid var(--gray);
  border-radius: 4px;
}

.status {
  display: block;
  color: var(--gray);
}</style>
