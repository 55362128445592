<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import { mapCompanyInformation } from "helpers/helpers";
;
import { companiesStore } from "stores/companiesStore";
import { vatTypesStore } from "stores/vatTypesStore";
import Spinner from "../../../../shared/Spinner/index.svelte";
import { getVatTypeLabel } from "stores/utils/vatTypeUtils";
const { companies, isLoading, companiesCells, companyBySuggestion, deleteCompany } = companiesStore;
const { vatTypes } = vatTypesStore;
export let isOurCompany;
export let isEditMode;
export let editingCompanyId;
export let companyModal;
export let companyModalClose;
export let selectedCompanyType;
export let selectedVatType;
export let inn;
const handleEdit = (item) => __awaiter(void 0, void 0, void 0, function* () {
    inn = item.details.inn;
    companyBySuggestion.set(mapCompanyInformation(undefined, item.details.inn, item, $vatTypes));
    //@ts-ignore
    isOurCompany = $companyBySuggestion.is_our_company.value;
    //@ts-ignore
    selectedCompanyType = $companyBySuggestion.type_id.value;
    //@ts-ignore
    selectedVatType = $companyBySuggestion.vat_type_id.value;
    isEditMode = true;
    editingCompanyId = item.id;
    companyModal === null || companyModal === void 0 ? void 0 : companyModal.open();
});
const handleDelete = (item) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield deleteCompany(item.id);
    if (res.success) {
        companyModalClose();
    }
});
</script>

<DataTable>
  <Head>
    <Row>
      {#each companiesCells as cell}
        <Cell width="200px">{cell}</Cell>
      {/each}
    </Row>
  </Head>
  <Body>
    {#if $isLoading || !$companies.companies}
      <Spinner />
    {:else}
      {#each $companies.companies as item}
        <Row>
          <Cell><a href="/" on:click|preventDefault={() => handleEdit(item)}>{item.name || ""}</a></Cell>
          <Cell>{item.details.inn || ""}</Cell>
          <Cell>{item.details.name || ""}</Cell>
          <Cell>{item.details.kpp || ""}</Cell>
          <Cell>{item.details.bank.bic || ""}</Cell>
          <Cell>{item.details.bank.name || ""}</Cell>
          <Cell>{item.details.bank["correspondent account"] || ""}</Cell>
          <Cell>{item.details.payment_account || ""}</Cell>
          <Cell>{item.details.address || ""}</Cell>
          <Cell>{item.type.name || ""}</Cell>
          <Cell>{getVatTypeLabel(item.vat_type?.percentage_rate || null)}</Cell>
          <Cell>{item.is_our_company ? "да" : "нет"}</Cell>
          <Cell>
            <a href="/" on:click|preventDefault={() => handleEdit(item)}>Редактировать</a>&nbsp;
            <a class="delete" href="/" on:click|preventDefault={() => handleDelete(item)}>Удалить</a
            >
          </Cell>
        </Row>
      {/each}
    {/if}
  </Body>
</DataTable>

<style lang="scss">.delete {
  color: red;
  transition: all 0.2s ease;
}
.delete:hover {
  opacity: 0.8;
}</style>
