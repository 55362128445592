<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Dialog, { Title } from "@smui/dialog";
import Button from "@smui/button";
import { companiesStore } from "stores/companiesStore";
import Spinner from "../../../../shared/Spinner/index.svelte";
import CompanyFormField from "../CompanyFormField.svelte";
import { mapCompanyRequestInformation } from "helpers/helpers";
;
export let companyModal;
export let companyModalClose;
export let inn;
export let isOurCompany;
export let selectedCompanyType;
export let selectedVatType;
export let errors;
export let isEditMode;
export let editingCompanyId;
export let editInn;
const { companyBySuggestion, isLoading, editCompany, addCompany } = companiesStore;
const saveCompany = () => __awaiter(void 0, void 0, void 0, function* () {
    const res = isEditMode
        ? yield editCompany(
        //@ts-ignore
        mapCompanyRequestInformation($companyBySuggestion, selectedCompanyType, selectedVatType, isOurCompany), editingCompanyId)
        : yield addCompany(
        //@ts-ignore
        mapCompanyRequestInformation($companyBySuggestion, selectedCompanyType, selectedVatType, isOurCompany));
    if (res.success) {
        companyModalClose();
    }
    else {
        errors = res.message;
    }
});
</script>

<div class="companies__modal">
  <Dialog bind:this={companyModal} on:MDCDialog:closed={companyModalClose}>
    <Title id="simple-title">Контрагент - {editInn || inn}</Title>
    <div class="companies-modal__fields-wrap">
      {#if $isLoading}
        <div class="spinner-container">
          <Spinner />
        </div>
      {:else}
        {#each Object.keys($companyBySuggestion) as key}
          <CompanyFormField
            invalidValue={errors[key]}
            bind:value={$companyBySuggestion[key].value}
            label={$companyBySuggestion[key].label}
            type={$companyBySuggestion[key].type}
            validate={$companyBySuggestion[key].validate}
            bind:selectedCompanyType={selectedCompanyType}
            bind:selectedVatType={selectedVatType}
            bind:checked={isOurCompany}
          />
        {/each}
      {/if}
    </div>

    <footer class="companies-modal__buttons">
      <Button on:click={saveCompany}>Сохранить</Button>
    </footer>
  </Dialog>
</div>

<style lang="scss">.companies-modal__buttons {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 15px;
}
.companies-modal__fields-wrap {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}</style>
