<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
import Button from "@smui/button";
import Dialog, { Content, Title } from "@smui/dialog";
import TextInput from "../../shared/Fields/Text.svelte";
import RolesSelect from "../../shared/Fields/SelectRoles/index.svelte";
import NumberInput from "../../shared/Fields/Number.svelte";
import Date from "../../shared/Fields/Date.svelte";
import Spinner from "../../shared/Spinner/index.svelte";
import EditingButton from "../../shared/Button/Editing/index.svelte";
import SelectBillingType from "../../shared/Fields/SelectBillingType/index.svelte";
import SelectAdjustments from "../../shared/Fields/SelectAdjustments/index.svelte";
import SelectProjectOffice from "../../shared/Fields/SelectProjectOffice/index.svelte";
import { authStore } from "stores/authStore";
import { usersStore } from "stores/usersStore";
const { hasPermission } = authStore;
const { user, updateUser, updateUserStore, updateUsersStore, updateUsersDetailedStore, users, usersDetailed } = usersStore;
let isCanEdit = hasPermission(["users_access", "users_access_money"]);
let isCanEditCommercial = hasPermission(["users_access_money"]);
let modal;
let activeField = null;
let activeValue = null;
let defaultValue = null;
let isUpdatingUser = false;
const setActiveField = (name, component, baseValue, nameForServer, label, props = {}) => {
    activeField = { name, component, props, nameForServer, label };
    defaultValue = baseValue;
    activeValue = baseValue;
    modal.open();
};
const handleChange = (value) => {
    activeValue = value;
};
function handleCloseModal() {
    modal.close();
    activeField = null;
    activeValue = null;
    defaultValue = null;
}
const handleSave = (name, value) => __awaiter(void 0, void 0, void 0, function* () {
    let valueToServer = value;
    if (Array.isArray(value)) {
        if (value.length === 0)
            return;
        else {
            valueToServer = value.map((item) => item.id);
            value = value.map((item) => item.name);
        }
    }
    else if (value && typeof value === "object") {
        valueToServer = value.id;
    }
    const data = { [name]: valueToServer };
    if ($user === null || $user === void 0 ? void 0 : $user.id) {
        //@ts-ignore
        const { success, message } = yield updateUser(data, $user.id);
        if (success) {
            updateUserStore(value, activeField.name);
            //@ts-ignore
            if ($users === null || $users === void 0 ? void 0 : $users.length)
                updateUsersStore(value, activeField.name, $user.id);
            //@ts-ignore
            if ($usersDetailed === null || $usersDetailed === void 0 ? void 0 : $usersDetailed.length)
                updateUsersDetailedStore(value, activeField.name, $user.id);
            handleCloseModal();
        }
        else
            notifier.danger(message, 2000);
    }
});
$: btnsInColumn = [
    {
        name: "Email",
        field: "email",
        label: "Введите email",
        fieldForServer: "email",
        value: (_a = $user === null || $user === void 0 ? void 0 : $user.email) !== null && _a !== void 0 ? _a : "",
        btnName: $user === null || $user === void 0 ? void 0 : $user.email,
        isEditable: isCanEdit,
        component: TextInput,
        props: {},
        baseValue: "Не указано"
    },
    {
        name: "Позиция",
        field: "position",
        label: "Введите позицию",
        fieldForServer: "position",
        value: (_b = $user === null || $user === void 0 ? void 0 : $user.position) !== null && _b !== void 0 ? _b : "",
        btnName: $user === null || $user === void 0 ? void 0 : $user.position,
        isEditable: isCanEdit,
        component: TextInput,
        props: {},
        baseValue: "Не указано"
    },
    {
        name: "Роли",
        field: "roles",
        label: "Выберите роли",
        fieldForServer: "roles",
        value: $user === null || $user === void 0 ? void 0 : $user.roles,
        btnName: (_c = $user === null || $user === void 0 ? void 0 : $user.roles) === null || _c === void 0 ? void 0 : _c.join(", "),
        isEditable: isCanEdit,
        component: RolesSelect,
        props: {
            defaultValue: $user === null || $user === void 0 ? void 0 : $user.roles
        },
        baseValue: "Не указано"
    },
    {
        name: "ID в Redmine",
        field: "redmineId",
        fieldForServer: "redmine_id",
        label: "ID",
        value: $user === null || $user === void 0 ? void 0 : $user.redmineId,
        btnName: $user === null || $user === void 0 ? void 0 : $user.redmineId,
        isEditable: isCanEdit,
        component: NumberInput,
        props: {},
        baseValue: "Не указано"
    },
    {
        name: "Баланс коинов",
        field: "coins",
        label: "Койны",
        fieldForServer: "coins",
        value: (_d = $user === null || $user === void 0 ? void 0 : $user.coins) !== null && _d !== void 0 ? _d : 0,
        btnName: $user === null || $user === void 0 ? void 0 : $user.coins,
        isEditable: false,
        component: NumberInput,
        props: {},
        baseValue: "Не указано"
    },
    {
        name: "Ежедневная плановая выработка",
        field: "dayHours",
        label: "Выработка",
        fieldForServer: "day_hours",
        value: (_e = $user === null || $user === void 0 ? void 0 : $user.dayHours) !== null && _e !== void 0 ? _e : "",
        btnName: $user === null || $user === void 0 ? void 0 : $user.dayHours,
        isEditable: isCanEdit,
        component: TextInput,
        props: {},
        baseValue: "Не указано"
    },
    {
        name: "Формат оплаты",
        field: "billingType",
        fieldForServer: "billing_type",
        label: "Выберите формат",
        value: $user === null || $user === void 0 ? void 0 : $user.billingType,
        btnName: $user === null || $user === void 0 ? void 0 : $user.billingType,
        isEditable: isCanEditCommercial,
        component: SelectBillingType,
        baseValue: "Не указано",
        props: {
            selectedValue: { name: $user === null || $user === void 0 ? void 0 : $user.billingType, id: $user === null || $user === void 0 ? void 0 : $user.billingType }
        }
    },
    {
        name: "Формат оформления",
        field: "adjustment",
        fieldForServer: "adjustment_id",
        label: "Выберите формат",
        value: $user === null || $user === void 0 ? void 0 : $user.adjustment,
        btnName: (_f = $user === null || $user === void 0 ? void 0 : $user.adjustment) === null || _f === void 0 ? void 0 : _f.name,
        isEditable: isCanEditCommercial,
        component: SelectAdjustments,
        baseValue: "Не указано",
    },
    {
        name: "Ставка специалиста",
        field: "rate",
        fieldForServer: "rate",
        label: "Выберите число",
        value: $user === null || $user === void 0 ? void 0 : $user.rate,
        btnName: (_g = $user === null || $user === void 0 ? void 0 : $user.rate) !== null && _g !== void 0 ? _g : 0,
        isEditable: isCanEditCommercial,
        component: NumberInput,
        props: {},
        baseValue: "Не указано"
    },
    {
        name: "Проектный офис",
        field: "projectOffice",
        label: "Выберите офис",
        fieldForServer: "project_office_id",
        value: $user === null || $user === void 0 ? void 0 : $user.projectOffice,
        btnName: (_h = $user === null || $user === void 0 ? void 0 : $user.projectOffice) === null || _h === void 0 ? void 0 : _h.name,
        isEditable: isCanEdit,
        component: SelectProjectOffice,
        baseValue: "Не указано"
    },
    {
        name: "Дата приёма",
        field: "hiringDate",
        label: "Установите дату",
        fieldForServer: "hiring_date",
        value: (_j = $user === null || $user === void 0 ? void 0 : $user.hiringDate) !== null && _j !== void 0 ? _j : '',
        btnName: $user === null || $user === void 0 ? void 0 : $user.hiringDate,
        isEditable: isCanEdit,
        component: Date,
        baseValue: ""
    },
    {
        name: "Дата увольнения",
        field: "terminationDate",
        label: "Установите дату",
        fieldForServer: "termination_date",
        value: (_k = $user === null || $user === void 0 ? void 0 : $user.terminationDate) !== null && _k !== void 0 ? _k : '',
        btnName: $user === null || $user === void 0 ? void 0 : $user.terminationDate,
        isEditable: isCanEdit,
        component: Date,
        baseValue: ""
    }
];
</script>

<NotificationDisplay />
<section class="section user">
  {#if isUpdatingUser}
    <div class="_with-air">
      <Spinner />
    </div>
  {:else if $user}
    <header>
      <h2 class="mdc-typography--headline4 ">{$user?.name}</h2>
    </header>
    <div class="buttons-column">
      {#each btnsInColumn as { name, field, label, isEditable, fieldForServer, value, component, props, btnName, baseValue }}
        <div class="row">
          <span>{name}</span>
          {#if isEditable}
            <EditingButton
              disabled={false}
              on:click={() => {
                setActiveField(field, component, value, fieldForServer, label, props);
              }}
            >
              {btnName ?? baseValue}
            </EditingButton>
          {:else}
            <EditingButton>
              {btnName ?? baseValue}
            </EditingButton>
          {/if}
        </div>
      {/each}
    </div>
    <Dialog on:MDCDialog:closed={handleCloseModal} bind:this={modal}>
      {#if isUpdatingUser}
        <Content>
          <Spinner />
        </Content>
      {:else if activeField}
        <Title>Редактировать данные пользователя</Title>
        <Content>
          <!--            Сюда должен прилетать компонент, который эмитит событие changeValue с валидным значением -->
          <svelte:component
            this={activeField.component}
            value={defaultValue}
            label={activeField.label}
            on:changeValue={(event) => handleChange(event.detail)}
            {...activeField.props}
          />
        </Content>
        <footer class="buttons">
          <Button
            on:click={() => handleSave(activeField.nameForServer, activeValue)}
            disabled={false}
          >
            Сохранить
          </Button>
        </footer>
      {/if}
    </Dialog>
  {/if}
</section>

<style lang="scss">.user :global(.mdc-dialog__title + .mdc-dialog__content) {
  padding-top: 10px;
}
.user :global(.mdc-dialog__surface),
.user :global(.mdc-dialog__content) {
  overflow-y: visible;
  overflow: inherit;
}
.user :global(.mdc-text-field) {
  width: 100%;
}

.buttons-column {
  display: inline-block;
  flex-direction: column;
}

header {
  margin-bottom: 20px;
}

.row {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.row > span {
  margin-right: 10px;
}

footer.buttons {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 15px;
}</style>
