<script lang="typescript">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Button from '@smui/button';
import { Icon } from '@smui/common';
import SelectPeriod from "../../../shared/SelectPeriod/index.svelte";
import SelectProject from "../../../shared/SelectProject/index.svelte";
import { actsStore } from "stores/actsStore";
;
const { acts, getActs } = actsStore;
let dateTo = "";
let dateFrom = "";
let selectedProject;
let filters = {};
const updateFilters = (newValues) => {
    filters = Object.assign(Object.assign({}, filters), newValues);
};
const handleUseFilters = () => __awaiter(void 0, void 0, void 0, function* () {
    yield getActs(filters);
});
const clearFilters = () => {
    dateTo = "";
    dateFrom = "";
    selectedProject = null;
    filters = {};
};
const handleClearFilters = () => {
    clearFilters();
    handleUseFilters();
};
const handleSelectFilterProject = (event) => {
    updateFilters({ projectId: event.detail.id });
};
const handleSelectFilterPeriod = (event) => {
    if (event.detail.name === "dateTo")
        dateTo = event.detail.value;
    else
        dateFrom = event.detail.value;
    updateFilters({ [event.detail.name]: event.detail.value });
};
const handleClearFilterProject = () => {
    delete filters.projectId;
};
</script>

<div class="filters">
  <div class="wrap">
    <SelectProject
      on:clear={handleClearFilterProject}
      selectedValue={selectedProject}
      on:select={handleSelectFilterProject}/>
    <SelectPeriod
      on:select={handleSelectFilterPeriod}
      {dateTo}
      {dateFrom}/>
    <div class="btns">
      <Button variant="unelevated" on:click={handleUseFilters}>
        <Icon class="material-icons" style={`color:white;font-size: 25px; margin: -8px 0 0 -8px`}>
          search
        </Icon></Button>
      <Button variant="unelevated" on:click={handleClearFilters}>
        <Icon class="material-icons" style={`color:white;font-size: 25px;  margin: -8px 0 0 -8px`}>
          clear
        </Icon></Button>
    </div>
  </div>


</div>

<style lang="scss">.filters .wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.filters .wrap > :global(div) {
  margin-bottom: 10px;
}
.filters .wrap > :global(div):first-child {
  flex-grow: 1;
}
.filters .wrap > :global(div):not(:last-child) {
  margin-right: 10px;
}

.btns {
  display: flex;
  align-items: center;
}
.btns :global(button):not(:last-child) {
  margin-right: 5px;
}</style>


