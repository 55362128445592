<script lang="typescript">import { onMount } from "svelte";
import { tweened } from "svelte/motion";
import { linear } from "svelte/easing";
import { writable } from "svelte/store";
import Router, { push } from "svelte-spa-router";
import FullSideSpinner from "../shared/Spinner/FullSideSpinner.svelte";
import ModalPanel from "../shared/ModalPanel/index.svelte";
import Header from "./Header/index.svelte";
import Menu from "./Menu/index.svelte";
import Projects from "./Projects/index.svelte";
import PersonalPage from "./PersonalPage/index.svelte";
import DaysOff from "./DaysOff/index.svelte";
import Acts from "./Acts/index.svelte";
import Users from "./Users/index.svelte";
import Payouts from "./Payouts/index.svelte";
import Payment from "./Payment/index.svelte";
import Accounting from "./Accounting/index.svelte";
import Coins from "./Coins/index.svelte";
import CoinsStore from "./CoinsStore/index.svelte";
import { Paths } from "constants/path";
import { authStore } from "stores/authStore";
import { modalPanelStore } from "stores/modalPanelStore";
const { userData, isFetchGetMe, isAuth, getMe } = authStore;
const { isOpen: isModalPanelOpen } = modalPanelStore;
onMount(() => {
    if (!$isAuth) {
        push(Paths.auth);
    }
    else {
        getMe();
    }
});
const LOCAL_STORAGE_MENU_OPEN_KEY = "menuOpen";
const localStorageMenuOpen = localStorage.getItem(LOCAL_STORAGE_MENU_OPEN_KEY);
let isOpenMenu = writable(localStorageMenuOpen ? localStorageMenuOpen === "true" : true);
let margin = tweened(0, {
    easing: linear,
    duration: 250
});
isOpenMenu.subscribe((value) => {
    localStorage.setItem(LOCAL_STORAGE_MENU_OPEN_KEY, value.toString());
    $margin = value ? 285 : 30;
});
const routes = {
    "/": PersonalPage,
    "/acts/:value?": Acts,
    "/users/:value?": Users,
    "/days-off": DaysOff,
    "/projects/:tab?/:id?": Projects,
    "/payouts": Payouts,
    "/payouts/:id?": Payment,
    "/accounting/:tab?": Accounting,
    "/coins/:id?": Coins,
    "/store/:tab?": CoinsStore
};
</script>

<Header bind:isOpenMenu={$isOpenMenu} />
<Menu bind:isOpenMenu={$isOpenMenu} />
{#if $userData}
  <main class="content" style={`margin-left: ${$margin}px`}>
    <Router {routes} />
  </main>
{/if}
<FullSideSpinner isShow={$userData === null && $isFetchGetMe} />
{#if $isModalPanelOpen}
  <ModalPanel />
{/if}

<style lang="scss">.content {
  position: relative;
  margin-top: 48px;
}</style>
