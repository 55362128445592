<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { formatter } from "helpers/formatter";
import { createEventDispatcher } from "svelte";
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import Dialog, { Content, Title, Actions } from "@smui/dialog";
import Button, { Label } from "@smui/button";
import { Icon } from "@smui/common";
import Text from "../Fields/Text.svelte";
import Date from "../Fields/Date.svelte";
import InputNumber from "../Fields/Number.svelte";
import Spinner from "../Spinner/index.svelte";
import { authStore } from "stores/authStore";
import { deleteCost, postNewCost, putCost } from "stores/otherCostsStore";
;
export let costs;
const { hasPermission } = authStore;
const isEditAvailable = hasPermission(["projects_full_access", "projects_update"]);
const dispatch = createEventDispatcher();
let addAndEditDialog, deleteDialog, error = "", isCreatingCost = false, costIdToDelete, costIdToEdit, editMode = false;
const fieldsConfig = [
    {
        name: "name",
        label: "Имя*",
        component: Text
    },
    {
        name: "date",
        label: "Дата*",
        component: Date
    },
    {
        name: "price",
        label: "Стоимость*",
        component: InputNumber
    }
];
const values = {
    name: {
        isRequired: true,
        isValid: (value) => !!value,
        value: null
    },
    date: {
        isRequired: true,
        isValid: (value) => !!value,
        value: null
    },
    price: {
        isRequired: true,
        isValid: (value) => value > 0,
        value: null
    }
};
const checkValid = (values) => {
    let isValid = true;
    Object.keys(values).some((field) => {
        const input = values[field];
        if (input.isRequired && !input.isValid(input.value)) {
            isValid = false;
        }
        return !input.isValid(input.value);
    });
    return isValid;
};
const handleChange = (name, value) => {
    values[name].value = value;
};
const handleSave = () => {
    if (!checkValid(values)) {
        error = "Введите корректные значения";
        return;
    }
    else
        error = "";
    let resultObject = { milestone_id: costs.milestoneId };
    for (let key in values) {
        if (values.hasOwnProperty(key)) {
            if (values[key].value)
                resultObject[key] = values[key].value;
        }
    }
    saveCost(resultObject);
};
const saveCost = (otherCost) => __awaiter(void 0, void 0, void 0, function* () {
    isCreatingCost = true;
    const { message, success } = editMode
        ? yield putCost(Object.assign(otherCost, { id: costIdToEdit }))
        : yield postNewCost(otherCost);
    if (success) {
        dispatch("success", message);
    }
    else
        error = message;
    isCreatingCost = false;
});
function deleteCostHandler() {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield deleteCost(costIdToDelete);
        dispatch("delete", { response });
    });
}
function openDeleteDialog(id) {
    costIdToDelete = id;
    deleteDialog.open();
}
function prepareEditDialog(id) {
    const editingCost = costs.otherCosts.find((cost) => cost.id === id);
    costIdToEdit = id;
    if (editingCost) {
        editMode = true;
        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                values[key].value = editingCost[key];
            }
        }
        addAndEditDialog.open();
    }
}
function closeHandler() {
    if (editMode) {
        editMode = false;
        Object.keys(values).forEach((key) => {
            values[key].value = null;
        });
        costIdToEdit = null;
    }
    error = "";
}
const editIconsStyle = "cursor: pointer";
</script>

<div class="otherCosts">
  <div class="otherCosts__row">
    {#if costs.additionalCosts}
      <h3>
        Остальные издержки: {formatter.money(costs.additionalCosts)}
      </h3>
    {/if}
    {#if isEditAvailable}
      <Button variant="unelevated" on:click={addAndEditDialog.open}>
        <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 10px 5px 0`}>
          add
        </Icon>
        <Label>Добавить расход</Label>
      </Button>
    {/if}
  </div>
  {#if costs.additionalCosts}
    <DataTable table$aria-label="UserCost">
      <Head>
        <Row>
          <Cell width="200">Название</Cell>
          <Cell width="200">Сумма</Cell>
          {#if isEditAvailable}
            <Cell width="100" />
          {/if}
        </Row>
      </Head>
      <Body>
        {#if costs.fixedCosts}
          <Row>
            <Cell>Постоянные издержки</Cell>
            <Cell>{formatter.money(costs.fixedCosts)}</Cell>
          </Row>
        {/if}
        {#each costs.otherCosts as { price, name, id }, i (i)}
          <Row>
            <Cell>{name}</Cell>
            <Cell>{formatter.money(price)}</Cell>
            {#if isEditAvailable}
              <Cell>
                <Icon
                  class="material-icons"
                  title="Редактировать расход"
                  style={editIconsStyle}
                  on:click={() => prepareEditDialog(id)}
                >
                  edit
                </Icon>
                <Icon
                  class="material-icons"
                  title="Удалить расход"
                  style={editIconsStyle + ";color: red"}
                  on:click={() => openDeleteDialog(id)}
                >
                  close
                </Icon>
              </Cell>
            {/if}
          </Row>
        {/each}
      </Body>
    </DataTable>
  {:else}
    Расходы отсутствуют
  {/if}
</div>

<Dialog bind:this={addAndEditDialog} on:MDCDialog:closed={closeHandler}>
  <Title>{editMode ? "Редактирование расхода" : "Добавление расхода"}</Title>
  <Content>
    {#each fieldsConfig as { name, component, label }}
      <div class="field-wrap">
        <!--Сюда должен прилетать компонент, который эмитит событие changeValue с валидным значением -->
        <svelte:component
          this={component}
          value={values[name].value ?? ""}
          on:changeValue={(event) => handleChange(name, event.detail)}
          {label}
        />
      </div>
    {/each}
    <p>{error}</p>
    <footer>
      <Button on:click={handleSave} disabled={isCreatingCost} variant="raised">
        {editMode ? "Изменить расход" : "Создать расход"}
      </Button>
      <div>
        {#if isCreatingCost}
          <Spinner />
        {/if}
      </div>
    </footer>
  </Content>
</Dialog>

<Dialog bind:this={deleteDialog} aria-labelledby="simple-title" aria-describedby="simple-content">
  <Title id="simple-title">Вы уверены?</Title>
  <Content id="simple-content">Удалить расход?</Content>
  <Actions>
    <Button>
      <Label>No</Label>
    </Button>
    <Button on:click={deleteCostHandler}>
      <Label>Yes</Label>
    </Button>
  </Actions>
</Dialog>

<style lang="scss">.otherCosts {
  margin-top: 30px;
}
.otherCosts__row {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field-wrap {
  margin-bottom: 10px;
}
.field-wrap :global(label) {
  width: 100%;
}

footer {
  display: flex;
}
footer div {
  margin-left: 15px;
}</style>
