<script lang="typescript">import { link } from "svelte-spa-router";
import { Icon } from "@smui/common";
import { authStore } from "stores/authStore";
import { formatter } from "helpers/formatter";
const { userData } = authStore;
</script>

<a use:link href="/">
  <div class="avatar">
    <div class="row">
      <div class="avatar__imageWrapper">
        <img class="avatar__image" src="images/avatar.png" alt="avatar"/>
      </div>
      <Icon class="material-icons" style="color: #FFCC33">attach_money</Icon>
      {formatter.coins($userData?.coins || 0)}
    </div>
  </div>
  <div class="mdc-typography--headline6 "><span class="name"> {$userData?.name}</span></div>
  <div class="mdc-typography--subtitle2 position">{$userData?.position ?? null}</div>
</a>

<style lang="scss">.position {
  color: rgba(0, 0, 0, 0.6);
}

a .name {
  border-bottom: 2px solid transparent;
  transition: 0.4s border-bottom;
}
a:hover {
  text-decoration: none;
}
a:hover .name {
  border-bottom: 2px solid black;
}

.row {
  display: flex;
  align-items: center;
}

.avatar {
  display: flex;
  padding: 30px 0 20px;
}
.avatar__imageWrapper {
  margin-right: 20px;
  border-radius: 50%;
  width: 40px;
  overflow: hidden;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar__image {
  width: 33px;
}</style>
