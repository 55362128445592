<script lang="ts">import { onDestroy } from "svelte";
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import Spinner from "../Spinner/index.svelte";
import { logs, getMilestoneLogs, getProjectLogs, getActsLogs, getUserLogs } from "stores/logsStore";
;
export let params;
const logsMap = {
    project: getProjectLogs,
    milestone: getMilestoneLogs,
    acts: getActsLogs,
    users: getUserLogs
};
onDestroy(() => logs.set(null));
</script>

{#if params.type && params.id}
  {#await logsMap[params.type](params.id)}
    <div class="changeTables__spinnerWrapper">
      <Spinner />
    </div>
  {:then}
    {#if $logs}
      <DataTable table$aria-label="Changes">
        <Head>
          <Row>
            <Cell width="200">Дата изменения</Cell>
            <Cell width="200">Автор изменения</Cell>
            <Cell width="200">Измененное значение</Cell>
            <Cell width="200">Старое значение</Cell>
            <Cell width="200">Новое значение</Cell>
          </Row>
        </Head>
        <Body>
          {#each $logs as { timestamp, author, changes }, idx (idx)}
            <Row>
              <Cell rowspan={changes.length > 1 ? changes.length.toString() : 1}>{timestamp}</Cell>
              <Cell rowspan={changes.length > 1 ? changes.length.toString() : 1}>{author}</Cell>
              {#each changes as { field, values: { old: oldValue, new: newValue } }, i (i)}
                {#if i === 0}
                  <Cell>
                    {field}
                  </Cell>
                  <Cell>
                    {oldValue}
                  </Cell>
                  <Cell>
                    {newValue}
                  </Cell>
                {/if}
              {/each}
            </Row>
            {#if changes.length > 1}
              {#each changes as { field, values: { old: oldValue, new: newValue } }, i (i)}
                {#if i > 0}
                  <Row>
                    <Cell>
                      {field}
                    </Cell>
                    <Cell>
                      {oldValue}
                    </Cell>
                    <Cell>
                      {newValue}
                    </Cell>
                  </Row>
                {/if}
              {/each}
            {/if}
          {/each}
        </Body>
      </DataTable>
    {:else}
      <p class="changeTable__empty">Изменения не обнаружены</p>
    {/if}
  {/await}
{/if}

<style lang="scss">.changeTables__spinnerWrapper {
  display: flex;
  justify-content: center;
}

.changeTable__empty {
  text-align: center;
}</style>
