<script lang="typescript">export let name = "";
export let link = "";
export let icon;
</script>


<a href={link} rel="noopener noreferrer" target="_blank">
  <img src={icon} alt="" />
  <span> {name}</span>
</a>


<style lang="scss">a {
  display: flex;
  align-items: center;
}
a :global(img) {
  margin-right: 2px;
}
a:hover {
  text-decoration: none;
}

span {
  border-bottom: 1px solid transparent;
  transition: 0.4s all;
  color: #9761F6;
  font-weight: bold;
}
span:hover {
  text-decoration: none;
  border-bottom: 1px solid #9761F6;
}</style>
