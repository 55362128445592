<script lang="typescript">import { onMount } from "svelte";
import Select from 'svelte-select';
import debounce from "lodash.debounce";
import TextInput from "../../shared/Fields/Text.svelte";
import { usersStore } from "stores/usersStore";
import { rolesStore } from "../../../stores/rolesStore";
import { projectOfficesStore } from "../../../stores/projectOfficesStore";
;
const { roles, getRoles } = rolesStore;
const { users, getUsersDetailed } = usersStore;
const { projectOffices, getProjectsOffices } = projectOfficesStore;
// переменные, где хранятся введеные юзером фильтры
let name = "";
let project_office = [];
let role = [];
function getFilters() {
    return Object.assign({}, name ? { name } : {}, 
    //@ts-ignore
    (project_office === null || project_office === void 0 ? void 0 : project_office.length) ? { project_office: project_office.map(office => office.id) } : {}, 
    //@ts-ignore
    (role === null || role === void 0 ? void 0 : role.length) ? { role: role.map(r => r.id) } : {});
}
function handleSearchByName(e) {
    name = e.detail.toLowerCase();
    const filters = getFilters();
    getUsersDetailed(filters);
}
const debouncedSearchByName = debounce(handleSearchByName, 400);
const handleSelect = (name, event) => {
    if (name === "role") {
        role = event.detail;
    }
    else {
        project_office = event.detail;
    }
    const filters = getFilters();
    getUsersDetailed(filters);
};
const optionIdentifier = "id";
const getOptionLabel = (option) => option.name;
const getSelectionLabel = (option) => option.name;
onMount(() => {
    if (!$roles)
        getRoles();
    if (!$projectOffices)
        getProjectsOffices();
});
</script>

<div class="filters">
  <label for="">
    <p>Поиск по имени:</p>
    <TextInput placeholder="Введите имя" label={undefined} on:input={debouncedSearchByName}/>
  </label>
  <label for="">
    <p>Поиск по ролям:</p>
    <Select
      {optionIdentifier}
      placeholder="Выберите"
      {getSelectionLabel}
      {getOptionLabel}
      items={$roles ?? []}
      on:select={(value) => handleSelect("role",value)}
      isMulti={true}/>
  </label>
  <label for="">
    <p>Поиск по проектным офисам:</p>
    <Select
      {optionIdentifier}
      placeholder="Выберите"
      {getSelectionLabel}
      {getOptionLabel}
      items={$projectOffices ?? []}
      on:select={(value) => handleSelect("project_office",value)}
      isMulti={true}/>
  </label>

</div>


<style lang="scss">.filters {
  display: flex;
  padding: 10px 0;
}
.filters > label:first-child {
  width: 200px;
}
.filters > label:not(:first-child) {
  width: calc(50% - 100px);
  margin-left: 10px;
}
.filters > label:not(:first-child) > p {
  margin-bottom: 5px;
}
.filters > label :global(div.selectContainer) {
  height: 56px;
  padding-top: 6px;
}</style>


