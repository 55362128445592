<script lang="ts">import { onMount } from "svelte";
import { link } from "svelte-spa-router";
import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
import IconButton from "@smui/icon-button";
import { registriesStore } from "stores/registriesStore";
import { authStore } from "stores/authStore";
import Spinner from "../../shared/Spinner/index.svelte";
import Header from "../../shared/Header/index.svelte";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
const { userData } = authStore;
const { isLoading, salary, getSalary, unreadMessages } = registriesStore();
onMount(() => {
    //@ts-ignore
    ($userData === null || $userData === void 0 ? void 0 : $userData.id) && getSalary($userData.id);
});
</script>

<section class="section users">
  <Header title={`Реестры - ${$userData?.name}`} />
  <div class="coins__table">
    {#if $isLoading}
      <div class="spinner-container">
        <Spinner />
      </div>
    {:else}
      <DataTable>
        <Head>
          <Row>
            <Cell width="20px" />
            <Cell width="200px">Реестры</Cell>
            <Cell width="400px">Период</Cell>
          </Row>
        </Head>
        <Body>
          {#each $salary.salary_registries as { id, date_from, date_to }, index (id)}
            <Row>
              <Cell>
                {#if $unreadMessages[index]}
                  <span
                    class:_hasNotification={$unreadMessages[index]}
                    data-count-notifications={$unreadMessages[index]}
                  >
                    <a href={`/payouts/${id}`} use:link
                      ><IconButton class="material-icons topBarIcon">notifications</IconButton></a
                    >
                  </span>
                {/if}
              </Cell>
              <Cell><a class="link" href={`/payouts/${id}`} use:link>Реестр #{id}</a></Cell>
              <Cell
                ><a href={`/payouts/${id}`} use:link
                  >{format(
                    new Date(date_from), 
                    "d MMMM yyyy", 
                    { locale: ru })} 
                    - 
                    {format(
                    new Date(date_to),
                    "d MMMM yyyy",
                    { locale: ru }
                  )}</a
                ></Cell
              >
            </Row>
          {/each}
        </Body>
      </DataTable>
    {/if}
  </div>
</section>

<style lang="scss">.link {
  color: var(--violet);
}

._hasNotification {
  position: relative;
  cursor: pointer;
}
._hasNotification:after {
  content: attr(data-count-notifications);
  top: -20px;
  height: 19px;
  padding: 0px 4px;
  left: 30px;
  color: white;
  font-size: 11px;
  background-color: var(--red);
  border-radius: 20px;
  position: absolute;
}</style>
