<script lang="typescript">import active from "svelte-spa-router/active";
import { link } from "svelte-spa-router";
import Drawer, { Content, Header } from "@smui/drawer";
import List, { Item, Separator } from "@smui/list";
import UserInfo from "./UserInfo.svelte";
import { authStore } from "stores/authStore";
;
export let isOpenMenu;
let { hasPermission, userData } = authStore;
let myDrawer, links = [];
const projectPermissions = [
    "projects_read",
    "projects_add",
    "projects_update",
    "projects_delete",
    "projects_full_access",
    "projects_read_all"
];
const daysOffPermissions = ["daysoff_access"];
const usersPermissions = ["users_access", "users_access_money"];
const actsPermissions = [
    "acts_add",
    "acts_update",
    "acts_read_my_projects",
    "acts_full_access"
];
const coinsPermissions = [
    "coins_read_all",
    "coins_update",
    "coins_add",
    "coins_delete",
    "coins_full_access"
];
const accountingPermissions = ["invoices_read", "companies_read"];
const projectPayouts = {
    title: "Выплаты",
    path: "/payouts",
    activePath: "/payouts"
};
const linkMapToPermissions = new Map([
    [
        accountingPermissions,
        {
            title: "Фин. учет",
            path: "/accounting/accounts",
            activePath: "/accounting"
        }
    ],
    [
        actsPermissions,
        {
            title: "Акты",
            path: "/acts",
            activePath: "/acts/*"
        }
    ],
    [
        coinsPermissions,
        {
            title: "Коины",
            path: "/coins",
            activePath: "/coins/*"
        }
    ],
    [
        null,
        {
            title: "Coins Store",
            path: "/store",
            activePath: "/store"
        }
    ],
    [
        projectPermissions,
        {
            title: "Проекты",
            path: "/projects/projectsTab",
            activePath: "/projects/*"
        }
    ],
    [
        daysOffPermissions,
        {
            title: "Управление отсутствиями",
            path: "/days-off",
            activePath: "/days-off"
        }
    ],
    [
        usersPermissions,
        {
            title: "Управление пользователями",
            path: "/users",
            activePath: "/users"
        }
    ]
]);
//@ts-ignore
$: if ($userData)
    generateMenu($userData);
function generateMenu(userData) {
    if (userData) {
        if (userData.roles.includes("in_house_employee"))
            links.push(projectPayouts);
        for (let entry of linkMapToPermissions) {
            if (entry[0]) {
                if (hasPermission(entry[0]))
                    links.push(entry[1]);
            }
            else
                links.push(entry[1]);
        }
    }
}
</script>

{#if links.length && $userData}
  <Drawer bind:open={isOpenMenu} bind:this={myDrawer} class="mainMenu" variant="dismissible">
    <Header>
      <UserInfo />
    </Header>
    <Separator nav />
    <Content>
      <List>
        {#each links as { path, title, activePath }, index (index)}
          {#if title}
            <a href={path} use:link use:active={activePath}>
              <Item>
                {title}
              </Item>
            </a>
          {/if}
        {/each}
      </List>
    </Content>
  </Drawer>
{/if}

<style lang="scss">:global(.mainMenu) {
  padding-top: 5px;
  z-index: 4 !important;
  position: fixed !important;
  bottom: 0 !important;
  top: 48px !important;
}

:global(a.active .mdc-list-item) {
  color: #6200ee;
}</style>
